#requisitesSettings {
    padding: 13px;
    display: flex;
    flex-direction: column;
    width: 60%;
    flex-grow: 1;
}

#RequisitesSettingsForm {
    flex-grow: 1;
    position: relative;
}

.requisitesSettingsTitle {
    font-family: "Roboto-Medium";
    display: flex;
    align-items: center;
}

#RequisitesSettingsButtonsBlock {
    display: flex;
    align-items: center;
}

#RequisitesSettingsButtonSave {
    font-family: "Roboto-Medium";
    cursor: pointer;
    padding: 10px 25px;
    border: 1px solid #326FB5;
    color: #326FB5;
    transition: 0.3s;
    background: white;
    border-radius: 5px;
    margin-right: 30px;
}

#RequisitesSettingsButtonSave:hover {
    color: white;
    background: #326FB5;
}

#RequisitesSettingsButtonCancel {
    font-family: "Roboto-Medium";
    cursor: pointer;
    color: #326FB5;
    transition: 0.3s;
    border-radius: 5px;
    opacity: 0.6;
}

#RequisitesSettingsButtonCancel:hover {
    opacity: 1;
}

.textValidSettingsContractors {
    position: inherit;
}

#RequisitesSettingsFormBlocks {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: absolute;
    height: 100%;
    padding-right: 13px;
    width: 100%;
}
#RequisitesSettingsFormBlocks > div{
    width: 100%;
}

@media (min-width:992px) 
{
    #RequisitesSettingsFormBlocks {
        flex-direction: row;
    }
    #RequisitesSettingsFormBlocks > div{
        width: 50%;
    }
    #RequisitesSettingsFormBlocks > div:first-child{
        margin-right: 13px;
    }
    #RequisitesSettingsFormBlocks > div:last-child{
        margin-left: 13px;
    }
}
#addcontractor{
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 20px;
}
.addcontractorform{
    flex-grow: 1;
    width: 600px;
    display: none;
}
.addcontractorform.Active{
    display: block;
}
.addcontractorformlabel{
    font-size: 10px;
    font-family: "Roboto-Regular";
    background: white;
    padding: 3px;
    position: absolute;
    margin-left: 15px;
    cursor: text;
    top:0;
    left: 0;
    transition: 0.3s;
    margin-top: -10px;
    color: #A7A7A7;
}
.addcontractorformlabelselect{
    font-size: 10px;
    font-family: "Roboto-Regular";
    background: white;
    padding: 3px;
    position: absolute;
    margin-left: 15px;
    cursor: text;
    top:0;
    left: 0;
    transition: 0.3s;
    margin-top: -10px;
    color: #A7A7A7;
    margin-top: 15px;
    font-size: 12px;
    cursor: pointer;
    z-index: -1;
}
.addcontractorforminput:focus ~ .addcontractorformlabelselect{
    margin-top: -10px;
    color: #326FB5;
    font-size: 10px;
    z-index: 1;
}
.addcontractorformlabelselect.Valid{
    margin-top: -10px;
    font-size: 10px;
    z-index: 1;
}
.addcontractorforminput{
    outline: none;
    border-radius: 5px;
    border: 1px solid #AFB8BF;
    padding: 15px;
    font-family: "Roboto-Regular";
    color: #0D1012;
    width: -webkit-fill-available;
    transition: 0.4s;
    background: transparent;
}
.addcontractorforminput.select{
    cursor: pointer;
}
#addcontractorformgroupselect{
    display: flex;
    justify-content: space-between;
}
.addcontractorformselect{
    width: calc(100% / 3 - 10px);
}
.addcontractorforminput::placeholder{
    color: transparent;
}
.addcontractorforminput:focus{
    border: 1px solid #326FB5;
}
.addcontractorforminput:placeholder-shown ~ .addcontractorformlabel{
    margin-top: 15px;
    font-size: 12px;
}
.addcontractorforminput:focus ~ .addcontractorformlabel{
    margin-top: -10px;
    color: #326FB5;
    font-size: 10px;
}
.addcontractorforminputblock{
    position: relative;
    margin-bottom: 20px;
}
.addcontractorforminputgroupblock{
    display: flex;
    justify-content: space-between;
}
.addcontractorforminputgroupblock.hidden{
    display: none;
}
#addcontractorsavebutton{
    font-family: "Roboto-Medium";
    cursor: pointer;
    padding: 10px 25px;
    border:1px solid #326FB5;
    color:#326FB5;
    transition: 0.3s;
    background: white;
    border-radius: 5px;
    margin-right: 30px;
}
#addcontractorsavebutton:hover{
    color:white;
    background:#326FB5;
}
#addcontractorcancelbutton{
    font-family: "Roboto-Medium";
    cursor: pointer;
    color:#326FB5;
    transition: 0.3s;
    border-radius: 5px;
    opacity: 0.6;
}
#addcontractorcancelbutton:hover{
    opacity: 1;
}
#Addcontractorform > .addcontractorforminputblock{
    width: 100%;
}
.addcontractorforminputgroupblock > .addcontractorforminputblock{
    width: 49%;
}
.addcontractorforminputvalid{
    color: #F34F49;
    font-family: "OpenSans-Semibold";
    font-size: 12px;
    display: none;
}
.addcontractorforminputvalid.Invalid{
    display: block;
}
#Addcontractorpageblock{
    display: flex;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 13px;
}
.addcontractorpage{
    color:#326FB5;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-right: 5px;
    padding: 5px 15px;
    cursor: pointer;
    font-family: "Roboto-Regular";
    display: flex;
    align-items: center;
}
.addcontractorpage.Active{
    background: #326FB5;
    color: white;
    cursor: default;
}
.addcontractorpage:last-child{
    margin-right: 0;
}
.addcontractorValidIcon{
    margin-right: 5px;
}
#addcontractorbuttonsblock{
    display:flex;
    align-items: center;
}
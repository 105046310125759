#menu{
    padding: 0 15px;
    width: 130px;
    height: 100%;
    background: #326FB5;
    box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.12);
    z-index: 1000;
}
.MenuItem{
    margin-top: 15px;
    padding: 15px;
    background: #326FB5;
    font-family: "OpenSans-Regular";
    color: #AFB8BF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: all 0.3s linear;
    cursor: pointer;
    width: 100px;
}
.MenuItem:hover{
    background: #afb8bf33;
}
.MenuItem.active{
    background: white;
    color: #326FB5;
    cursor: default;
}
.MenuItemIconBlock{
    width: 32px;
    height: 32px;
    position: relative;
    margin: 0 auto;
    display: flex;
}
.MenuItemIcon{
    position: absolute;
    margin: 0 auto;
    font-size: 20px;
    width: 32px;
    height: 32px;
    position: absolute;
    transition: all 0.3s linear ;
}
.MenuItem > .MenuItemIconBlock > .MenuItemIcon.Off{
    opacity: 1;
}
.MenuItem > .MenuItemIconBlock > .MenuItemIcon.On{
    opacity: 0;
}
.MenuItem.active > .MenuItemIconBlock > .MenuItemIcon.Off{
    opacity: 0;
}
.MenuItem.active > .MenuItemIconBlock > .MenuItemIcon.On{
    opacity: 1;
}
#MenuLogoBlock{
    display: flex;
    height: 42px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
}
#MenuLogo{
    cursor: pointer;
    height: 30px;
}
#MenuLogoWhiteText{
    font-family: "OpenSans-SemiBold";
    color:white;
    margin-right: 5px;
}
#MenuLogoBlackTextBlock{
    background: white;
    padding: 0 2px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}
#MenuLogoBlackText{
    font-family: "OpenSans-Bold";
    font-weight: bold;
    color: black;
}
#MenuLogoColorBlock{
    margin-right: 2px;
}
.menuLogoColor{
    height: 5px;
    width: 9px;
}
#MenuLogoColorBlack{
    background:black;
}
#MenuLogoColorRed{
    background:#E34A39;
}
#MenuLogoColorYellow{
    background:#EFC944;
}


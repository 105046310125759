#professions{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex-grow: 1;
}
#ProfessionsBlock{
    background: #F6F6F6;
    flex-grow: 1;
    padding: 13px;
    display: flex;
    flex-direction: column;
    position: relative;
}
#ProfessionsBlockContent{
    position: absolute;
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    overflow: auto;
}
.ProfessionsItem{
    padding: 13px;
    border-radius: 5px;
    background: white;
    font-family: "Roboto-Regular";
    color: black;
    height: max-content;
    margin-right: 13px;
    margin-bottom: 13px;
    display: flex;
    font-size: 16px;
    align-items: center;
}
.ProfessionsItemNumber{
    margin-right: 5px;
    color: #A7A7A7;
}
.ProfessionsItemTitle{
    cursor: pointer;
    transition: 0.3s;
    margin-right: 5px;
    flex-grow: 1;
}
.ProfessionsItemTitle:hover{
    color: #A7A7A7;
}
.ProfessionsItemTitleInput{
    outline: none;
    color: #A7A7A7;
    font-family: "Roboto-Regular";
    padding: 0;
    border:none;
    flex-grow: 1;
    font-size: 16px;
    height: 19px;
}
.ProfessionsItemTitleRemoveIcon{
    cursor: pointer;
    margin-right: 13px;
    color: #a7a7a7;
    transition: 0.3s;   
    opacity: 0;
}
.ProfessionsItemTitleRemoveIcon:hover{
    color: black;
}
.ProfessionsItem:hover > .ProfessionsItemTitleRemoveIcon{
    opacity: 1;
}

/* Блок добавления */
#ProfessionsAddBLock{
    background: white;
    padding: 13px;
    width: calc(100% - 26px);
    border-bottom: 1px solid #e6e6e6;
    height: 30px;
}
#ProfessionsAddButtonBlock{
    padding: 5px 13px;
    cursor: pointer;
    transition: 0.3s;
    font-family: "Roboto-Regular";
    color:#32B596;
    border: 1px solid #32B596;
    border-radius: 5px;
    font-size: 14px;
    width: max-content;
}
#ProfessionsAddButtonBlock:hover{
    background: #32B596;
    color:white;
}
#ProfessionsAddInputBlock{
    display: flex;
    align-items: center;
}
#ProfessionsAddInput{
    border-radius: 5px;
    outline: none;
    border: 1px solid #e6e6e6;
    font-family: "Roboto-Regular";
    height: 23px;
    padding: 3px 13px;
    width: 300px;
    margin-right: 13px;
}
#ProfessionsAddInput::placeholder{
    font-family: "Roboto-Italic";
}
.ProfessionsAddBlockIcons{
    cursor: pointer;
    margin-right: 13px;
    color: #a7a7a7;
    transition: 0.3s;
}
.ProfessionsAddBlockIcons:hover{
    color: black;
}
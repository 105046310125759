#LinkContactPersonsBlock{
    border-radius: 5px;
    padding: 13px;
    background: white;
    width: 800px;
    font-family: "Roboto-Regular";
    font-size: 14px;
}
#LinkContactPersonsHeaderBlock{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#LinkContactPersonsHeaderTitle{
    font-family: "Roboto-Bold";
}
#LinkContactPersonsHeaderCloseIcon{
    color:#a7a7a7;
    cursor:pointer;
    transition: 0.3s;
    font-size: 16px;
    border-radius: 5px;
}
#LinkContactPersonsHeaderCloseIcon:hover{
    color: white;
    background: #a7a7a7;
}
.linkContactPersonItemBlock{
    background: #f9f9f9;
    padding: 13px;
    margin-bottom: 13px;
    border-radius: 5px;
    transition: 0.3s;
    display: flex;
    align-items: center;
}
.linkContactPersonItemBlock:hover{
    background: #e9e9e9;
}
.linkContactPersonItemBlock:hover > .linkContactPersonItemData > .linkContactPersonTitleBlock > .linkContactPersonEditIcon{
    opacity: 1;
}
.linkContactPersonItemData{
    flex-grow: 1;
}
.linkContactPersonTitleBlock{
    display: flex;
    align-items: center;
}
.linkContactPersonEditIcon{
    font-size: 14px;
    color:#a7a7a7;
    cursor: pointer;
    margin-left: 10px;
    opacity: 0;
    transition: 0.3s;
}
.linkContactPersonEditIcon:hover{
    color:black;
}
.linkContactPersonItemDataRow{
    display: flex;
    margin-top: 13px;
}
.linkContactPersonItemDataRowItem{
    width: 33%;
}
.linkContactPersontAddedButton{
    border-radius: 5px;
    padding: 5px 15px;
    background: white;
    border: 1px solid #326FB5;
    color: #326FB5;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    font-size: 12px;
    font-family: "OpenSans-Regular";
    width: max-content;
}
.linkContactPersontAddedButton:hover{
    background: #326FB5;
    color: white;
}
.linkContactPersonsInput{
    box-sizing: border-box;
    outline: none;
    border:none;
    padding: 5px 15px;
    font-family: "OpenSans-Regular";
    border-bottom: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 13px;
}
.linkContactPersonsInput::placeholder{
    font-family: "Roboto-Italic";
    color: #a7a7a7;
}
#LinkContactPersonsNotFoundBlock{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}
#LinkContactPersonsNotFoundText{
    font-family: "Roboto-Italic";
    margin-top: 13px;
    margin-bottom: 13px;
}
#LinkContactPersonsCreateButtonsBlock{
    display: flex;
    justify-content: space-between;
}
#LinkContactPersonsCreateButtonsCancel{
    color: #326FB5;
    cursor: pointer;
    font-family: "OpenSans-SemiBold";
}
.contactPersonsDataItemBlock{
    font-size: 14px;
    margin-bottom: 13px;
    font-family: "Roboto-Regular";
}
.contactPersonsDataItemBlock.contactPersonsTitleBlock{
    margin-bottom: 0px;
}
.contactPersonsTitleBlock{
    display: flex;
    align-items: center;
}
.contactPersonsTitle{
    padding: 13px;
    flex-grow: 1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.contactPersonsDetalizationButton{
    padding: 5px;
    transition: 0.3s;
    color: #a7a7a7;
    padding: 13px;
    border-radius: 5px;
    cursor: pointer;
    width: 14px;
    height: 14px;
}
.contactPersonsDetalizationButtonIcon{
    transition: 0.3s;
    transform: rotateZ(-90deg);
}
.contactPersonsDetalizationButtonIcon.Open{
    transform: rotateZ(90deg);
}
.contactPersonsDetalizationButton:hover{
    color: black;
    background: #f2f2f2;
}
.contactPersonsItemBlock{
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    background: #F9F9F9;
    margin-bottom: 13px;
    border-radius: 5px;
}
.contactPersonsItemData{
    font-family: "OpenSans-SemiBold";
}
.contactPersonsDetalizationBlock{
    transition: 0.3s;
    max-height: 0;
    overflow: hidden;
    padding: 0 13px;
}
.contactPersonsDetalizationBlock.Open{
    max-height: 300px;
}
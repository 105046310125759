#usersContractors{
    width: calc(100% - 26px);        
    height: calc(100% - 13px);
    display: flex;
    flex-direction: column;
    padding: 13px;
    padding-top: 0;
    position: absolute;
    overflow: auto;
}
#usersContractorsItemHeader{
    font-family: "OpenSans-Regular";
    border-bottom: 1px solid #e6e6e6;
    position: sticky;
    top: 0;
    margin-bottom: 13px;
    padding: 13px;
    padding-left: 0;
}
#usersContractorsItemHeaderNotUsers{
    margin-top: 30px;
    color: #a7a7a7;
    font-family: "Roboto-Medium";
    font-size: 14px;
    padding-left: 13px;
}
#usersContractorsItemHeaderColumns{
    margin-top: 30px;
    display: flex;
    padding-left: 13px;
}
.usersContractorsItemHeaderColumnsItem{
    width: 20%;
    color: #a7a7a7;
    font-family: "Roboto-Regular";
    font-size: 12px;
}
.usersContractorsItemHeaderColumnsItem:hover{
    cursor: default;
}
.usersContractorsItem{
    background: white;
    padding: 13px;
    font-family: "OpenSans-Regular";
    transition: 0.3s;
    margin-bottom: 13px;
    align-items: center;
    border-bottom:1px solid #e6e6e6;
    position: relative;
}
.usersContractorsItemDataRow{
    display: flex;
}
.usersContractorsItemDataRow:first-child{
    display: flex;
    align-items: center;
}
.usersContractorsItemButtonsBlock{
    align-items: baseline;
    height: 16px;
    overflow: hidden;
    transition: 0.5s;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
}
.usersContractorsItem:hover > .usersContractorsItemButtonsBlock{
    opacity: 1;
}
.usersContractorsItemButton{
    color:#8f8f8f;
    margin-right: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.usersContractorsItemButton:hover{
    color:black;
    margin-right: 5px;
    cursor: pointer;
}
.usersContractorsItemButton:last-child{
    margin-right: 0;
}
.usersContractorsItemColumnItem{
    width: 20%;
    color: black;
    font-family: "Roboto-Medium";
    word-break: break-all;
}
.usersContractorsItemColumnItem:hover{
    cursor: default;
}
#UsersContractorsModal{
    position: fixed;
    z-index: 1001;
    height: 100vh;
    width: 100vw;
    background: #3c3c3cb3;
    top: 0;
    left: 0;
    display: none;
}
#UsersContractorsModal.Open{
    display: flex;
}
#UsersContractorsModalContent{
    padding: 13px;
    background: white;
    width: 400px;
    height: max-content;
    border-radius: 7px;
    margin: auto auto;
}
#UsersContractorsModalHeader{
    font-family: "Roboto-Medium";
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e6e6e6;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#UsersContractorsModalText{
    font-family: "Roboto-Regular";
    font-size: 14px;
}
#UsersContractorsModalButtonsBlock{
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
}
.usersContractorsModalButton{
    padding: 4px 8px; 
    border-radius: 7px;
    color: white;
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.usersContractorsModalButton:hover{
    opacity: 1;
}
#UsersContractorsModalButtonYes{
    background: #32B596;
    margin-right: 10px;
}
#UsersContractorsModalButtonNo{
    background: #F04A4A;
}
.usersContractorsItemColumnItemBlockIcon{
    color: #F04A4A;
    margin-right: 5px;
    font-size: 12px;
}
#UsersContractorsModalBlockNoteBlock{
    margin-top: 13px;
    font-family: "Roboto-Regular";
    font-size: 14px;
}
#UsersContractorsModalBlockNote{
    resize: vertical;
    width: 100%;
    border:2px solid #e9e9e9;
    border-radius: 5px;
    height: 50px;
    outline: none;
    font-family: "Roboto-Italic";
}
#UsersContractorsModalBlockNote:focus{
    border:2px solid #326FB5;
}
#UsersContractorsModalBlockNoteValid{
    color: #F34F49;
    display: none;
    font-family: "Roboto-Medium";
    font-size: 14px;
}
#UsersContractorsModalBlockNoteValid.Open{
    display: block;
}
#UsersContractorsModalButtonBlock{
    display: flex;
    justify-content: flex-end;
}
#UsersContractorsModalButtonSave{
    font-family: "Roboto-Medium";
    cursor: pointer;
    padding: 10px 25px;
    border:1px solid #326FB5;
    color:#326FB5;
    transition: 0.3s;
    background: white;
    border-radius: 5px;
    width: max-content;
}
#UsersContractorsModalButtonSave:hover{
    color:white;
    background:#326FB5;
}
::-webkit-scrollbar {
  width: 10px;
  transition: all 0.3s linear;
}
::-webkit-scrollbar-track {
  opacity: 0;
}
::-webkit-scrollbar-thumb {
  background-color: #e9e9e9;
  border-radius: 7px;
  opacity: 0;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #d1d1d1;
}

a{
  color: inherit;
  text-decoration: none;
  cursor:inherit;
}

@font-face {
  font-family: "NunitoSans-Bold";
  src: url("./fontsfiles/Nunito-Bold.ttf");
}
@font-face {
  font-family: "NunitoSans-SemiBold";
  src: url("./fontsfiles/Nunito-SemiBold.ttf");
}
@font-face {
  font-family: "NunitoSans-Regular";
  src: url("./fontsfiles/Nunito-Regular.ttf");
}
@font-face {
  font-family: "OpenSans-Bold";
  src: url("./fontsfiles/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("./fontsfiles/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("./fontsfiles/OpenSans-SemiBold.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./fontsfiles/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./fontsfiles/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./fontsfiles/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("./fontsfiles/Roboto-Italic.ttf");
}

.CRMcontainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.PCBlock{
  display: none;
}
.MobileBlock{
  display: block;
}

.AddButton{
  border: 1px solid #32B596;
  outline: none;
  color: #32B596;
  transition: all 0.3s linear;
  font-family: "OpenSans-SemiBold";
  width: max-content;
  border-radius: 5px;
  padding: 5px 15px;
  background: white;
  cursor: pointer;
  /* margin-bottom: 25px; */
  font-size: 12px;
}
.AddButton:hover{
  color: white;
  background: #32B596;
}

/* Стилизация формы */
.forminputgroupblock, .forminputmonoblock{
  display: flex;
  justify-content: space-between;
}
.forminputmonoblock{
  display: flex;
  flex-direction: column;
}
.forminputgroupblock > .forminputblock:first-child{
  margin-right: 5px;
}
.forminputgroupblock > .forminputblock:last-child{
  margin-left: 5px;
}
.forminputgroupblock > .forminputblock{
  width: 50%;
}
.forminputblock{
  position: relative;
  margin-bottom: 20px;
}
.forminput{
  outline: none;
  border-radius: 5px;
  border: 1px solid #AFB8BF;
  padding: 15px;
  font-family: "Roboto-Regular";
  color: #0D1012;
  width: -webkit-fill-available;
  transition: 0.4s;
  background: transparent;
}
.forminput.Invalid{
  border: 1px solid #F34F49;
}
.forminput.disabled{
  background: #e9e9e9a6;
}
.forminput:focus ~ .formlabelselect{
  margin-top: -10px;
  color: #326FB5;
  font-size: 10px;
  z-index: 1;
}
.forminput.select{
  cursor: pointer;
}
.forminput::placeholder{
  color: transparent;
}
.forminput:focus{
  border: 1px solid #326FB5;
}
.forminput:placeholder-shown ~ .formlabel{
  margin-top: 15px;
  font-size: 12px;
}
.forminput:focus ~ .formlabel{
  margin-top: -10px;
  color: #326FB5;
  font-size: 10px;
}
.formlabel{
  font-size: 10px;
  font-family: "Roboto-Regular";
  background: white;
  padding: 3px;
  position: absolute;
  margin-left: 15px;
  cursor: text;
  top:0;
  left: 0;
  transition: 0.3s;
  margin-top: -10px;
  color: #A7A7A7;
  border-radius: 7px;
}
.formlabel.Invalid{
  color: #F34F49;
}
.forminputvalid{
  color: #F34F49;
  font-family: "OpenSans-Semibold";
  font-size: 12px;
  display: none;
  position: absolute;
}
.forminputvalid.Invalid{
  display: block;
}
/* --- */

/* Стилизация таблицы */
table{
  border-spacing: 0px;
  font-family: "Roboto-Medium";
}
th{
  color: #A7A7A7;
  font-size: 10pt;
  font-family: "Roboto-Regular";
  font-weight: normal;
}
.tablehead > th{
  border-bottom: 1px solid #E6E6E6;
  padding: 10px;
  padding-left: 0;
  font-size: 12px;
  text-align: left;
}
.tablehead > th:first-child{
  padding-left: 5px;
}
.tablebody > td{
  border-bottom: 1px solid #E6E6E6;
  padding: 15px;
  padding-left: 0;
  text-align: left;
  font-size: 14px;
  color: black;
}
.tablebody > td:first-child{
  padding-left: 5px;
}
/* --- */

/* Переключатель */
.switchcontainer {
  display: flex;
  margin-bottom: 20px;
}

.switchtext {
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  font-family: "Roboto-Regular";
  color: #444444;
  display: flex;
  align-items: center;
}

.toggle-button {
  position: relative;
  width: 34px;
  height: 19px;
  margin: 0;
  vertical-align: top;
  background: #ffffff;
  border: 1px solid #89B7EC;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.toggle-button::after {
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  width: 15px;
  height: 15px;
  background-color: #89B7EC;
  border-radius: 50%;
  transform: translateX(0);
  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.toggle-button:checked::after {
  transform: translateX(100%);
  background-color: #fff;  
}

.toggle-button:checked {
  background-color: #326FB5;
  border: 1px solid #326FB5;
}
/* --- */

.textHistoryLink{
  text-decoration: underline;
  transition: 0.3s;
  line-height: 24px;
  color: black;
  font-size: 14px;
  cursor: pointer;
  font-family: "Roboto-Medium";
}
.textHistoryLink:hover{
  color: #326FB5;
}

@media (min-width: 576px) {
  .CRMcontainer {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .CRMcontainer {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .CRMcontainer {
    max-width: 960px;
  }
}

@media (min-width: 1024px) {
  .PCBlock{
    display: block;
}
  .MobileBlock{
    display: none;
}
}

@media (min-width: 1200px) {
  .CRMcontainer {
    max-width: 1140px;
  }
}
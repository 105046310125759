@font-face {
    font-family: "NunitoSans-Regular";
    src: url("../../fontsfiles/Nunito-Regular.ttf");
  }

#MSheaderlogin{
    position: sticky;
    top: 0;   
    z-index: 1002;
}
#MSheader{ 
    background: white;
    background: #326FB5;
    z-index: 1000;
    box-shadow: 0px 4px 30px rgba(50, 111, 181, 0.1);
}
#MSheaderPC{
    padding: 28px;
    display: flex;
    justify-content: space-between;
}
#MSheaderPCMenu{
    display: flex;
}
#MSheaderPCMenuLoginButtonTitleIcon{
    font-size: 18px;
}
#MSheaderPCLogo{
    cursor: pointer;
}
#MSheaderPCLogoBlock{
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}
#MSheaderPCLogoWhiteText{
    color: white;
    font-family: "OpenSans-SemiBold";   
    margin-right: 2px;
}
#MSheaderPCLogoTextBlock{
    background: white;
    padding: 0 2px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}
#MSheaderPCLogoBlackText{
    font-family: "OpenSans-Bold";
    font-weight: bold;
    color: black;
}
#MSheaderPCLogoColorBlock{
    margin-right: 2px;
}
.msheaderPCLogoColor{
    height: 5px;
    width: 9px;
}
#MSheaderPCLogoColorBlack{
    background:black;
}
#MSheaderPCLogoColorRed{
    background:#E34A39;
}
#MSheaderPCLogoYellow{
    background:#EFC944;
}
.MSheaderPCMenuItem{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 35px;
    font-size: 16px;
    color: #7A7F83;    
    color: white;    
    font-family: 'NunitoSans-Regular';
    cursor: pointer;
    transition: 0.3s;
}
.MSheaderPCMenuItem:hover{
    color: #326FB5;
    color: #e4e4e4;    
}
#MSheaderPCMenuLoginButton{
    width: 121px;
    height: 41px;
    background: #A8D1ED;
    background: white;
    box-shadow: 0px 4px 20px 10px rgba(167, 167, 167, 0.1);
    border-radius: 5px;
    outline: none;
    border: none;
    color: #326FB5;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;
    margin: auto;
    cursor: pointer;
}
#MSheaderPCMenuLoginButton:hover{
    /* color: #FFFFFF; */
    background: #326FB5;
    background: #e9e9e9;
}
.MSheaderPCMenuLoginButtonTitle{
    margin-right: 10px;
}
#MSheaderMobile{
    background: white;
    background: #326FB5;
    position: relative;
    padding: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    }
#MSheaderMobileBlock{
    z-index: 999;
    position: relative;
    }
#MSheaderMobileMenuItemList{
    position: absolute;
}

#MSheaderMobileMenuLoginButton{
    width: 41px;
    height: 41px;
    background: linear-gradient(180deg, #D9F0FF 0%, #B6D9F0 100%); 
    background: white; 
    box-shadow: 0px 4px 20px 10px rgba(167, 167, 167, 0.1);
    border-radius: 50%;
    outline: none;
    border: none;
    color: #326FB5;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;
    margin: auto;
}
#MSheaderMobileMenuLoginButtonCross{
    width: 41px;
    height: 41px;
}
.MSheaderMobileMenuButton{
    font-size: 45px;
    color: #7a7f83;
    color: white;
}

#MSheaderPCMenuItemLanguageBlock{
    cursor: default;
    display: none;
    position: absolute;
    margin-top: 60px;
    background: white;
    text-align: center;
    padding: 20px 40px;
    font-size: 16px;
    color: #7A7F83;    
    font-family: 'NunitoSans-Regular';
    z-index: 1002;
    box-shadow: 0px 0px 10px 8px rgba(214, 214, 214, 0.2);
}
#MSheaderPCMenuItemLanguageBlock:before {
    border-bottom: 15px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: "";
    height: 0;
    left: 45%;
    position: absolute;
    top: -15px;
    width: 0;
  }

#MSheaderPCMenuItemLanguageBlock.Open {
    display: block;
}

#MSheaderPCMenuItemLanguageMainBlock{
    position: relative;
    justify-content: center;
    display: flex;
}

.MSheaderPCMenuItemLanguageBlockItem{
    font-size: 16px;
    color: #7A7F83;    
    font-family: 'NunitoSans-Regular';
    margin-bottom: 30px;
    cursor: pointer;
}
.MSheaderPCMenuItemLanguageBlockItem:last-child{
    margin-bottom: 0px;
}
.MSheaderPCMenuItemLanguageBlockItem:hover{
    color: #326FB5;
}
#MSHeaderMobileMenuList{
    position: absolute;
    top: -300%;
    background: white;
    left: 0;
    width: 100%;
    transition: all 0.3s linear;
}
#MSHeaderMobileMenuList.Open{
    top: 100%;
}
.MSHeaderMobileMenuItem{
    font-family: "NunitoSans-Regular";
    padding: 14px 28px ;
    font-size: 20px;
    color: #7A7F83;
    width: calc(100% - 56px);
    position: relative;
}
#MSHeaderMobileMenuLanguageList{
    padding-left: 28px;
    background: white;
    position: absolute;
    top:-100%;
    transition: all 0.3s linear;
    width: calc(100% - 28px);
}
#MSHeaderMobileMenuLanguageList.Open{
    top:100%
}
.MSHeaderMobileMenuItemZUP{
    position: relative;
    background: white;
    z-index: 997;
}
#checkloginblock{
    margin: auto 0;
}
#CheckloginBlockPersCab{
    display: flex;
    color: #444444;
    color: white;
}
.CheckloginBlockPersCabBlock{
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s linear;
}
.CheckloginBlockPersCabBlock:hover{
    color: #326FB5;
    color: #e4e4e4;
}
.CheckloginBlockPersCabBlock:first-child{
    margin-right: 35px;
}
.CheckloginBlockPersCabIcon{
    margin-right: 5px;
}

@media (min-width: 1024px) {

  }
#contractors{
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
#TableBlock{
    position: relative;
    flex-grow: 1;
}
#TableContent{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
}
#TableContractors{
    width: 100%;
}
#CPRowsContents::-webkit-scrollbar {
    width: 0px;
    height: 5px;
}

#CPRowsContents::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e9e9e9;
    border-radius: 7px;
}

#CPRowsContents::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 10px;
}

#CPRowsContents::-webkit-scrollbar-thumb:hover {
    background: #cfcfcf;
}

#cp {
    background: #a7a7a7;
    height: 100%;
    padding: 16px;
    overflow: auto;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    padding-top: 30px;
    transition: 0.3s;
}

#cp.check {
    background: #68532373;
}

#CPTitleBlock {
    margin-bottom: 5px;
    font-family: "Roboto-Bold";
    color: white;
}

#CPBack {
    position: absolute;
    top: 3px;
    left: 3px;
    color: white;
    font-family: "Roboto-Medium";
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

#CPPageBlock {
    position: relative;
    overflow: auto;
}

#CPPage {
    background: white;
    position: absolute;
    width: 100%;
}

.pageItem {
    position: absolute;
}

.cpPrintButtonBlock {
    display: flex;
    width: 100%;
    padding-top: 13px;
}

.cpPrintButton {
    background: #326FB5;
    color: white;
    font-family: "Roboto-Medium";
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 5px;
    transition: 0.3s;
    margin-right: 13px;
}

.cpPrintButton:hover {
    background: #2a64a5;
}

.pageInput {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    border-bottom: 1px dashed #e9e9e9;
}

.cpTextArea {
    padding: 0;
    margin: 0;
    border: 1px dashed #e9e9e9;
    resize: none;
    outline: none;
}

.tableCPColHeader {
    border-bottom: 1px solid black;
    padding-bottom: 1mm;
    margin-bottom: 1mm;
}

#ToWhom {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

#Total {
    flex-direction: column;
    display: flex;
    align-items: flex-end;
}

.totalItem {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.totalTextBold {
    font-weight: bold;
}

.totalInput {
    width: 1px;
    flex-grow: 1;
}

.totalInputRightText {
    text-align: end;
}

#RequisitesContractor {
    cursor: pointer;
}

.requisitesCPModalBlock {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;
    align-items: center;
    justify-content: center;
}

.requisitesCPModalBlock.Open {
    display: flex;
}

#AddLineButton {
    color: #1E5492;
    font-family: "OpenSans-Semibold";
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: max-content;
}

#AddLineButton:hover {
    color: #326FB5;
}

#AddLineIconButton {
    margin-right: 5px;
}

.rowTable {
    display: flex;
    position: relative;
}

.cpRequisitesFlex {
    display: flex;
}

#NumberBlock,
#Number {
    font-weight: bold;
}

.dataDocumentInputBlock {
    display: flex;
    align-items: center;
}

#CPFullScreen {
    position: absolute;
    top: 3px;
    right: 20px;
    color: white;
    font-family: "Roboto-Medium";
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

#CPFullScreenIcon {
    margin-right: 5px;
    font-size: 17px;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.cpRemoveIconRow {
    color: '#a7a7a7';
    cursor: 'pointer';
    transform: 'rotateZ(180deg)';
}

.cpRemoveIconRow {
    position: absolute;
    transition: 0.3s;
    color: #a7a7a7;
    cursor: pointer;
    transform: rotateZ(180deg);
}

.cpRemoveIconRow:hover {
    color: #DF5252;
}

#editVAT {
    width: 300px;
    background: white;
    border-radius: 7px;
    padding: 13px;
}

#EditVATTitle {
    font-family: "Roboto-Medium";
    font-size: 14pt;
}

#EditVATBlock {
    margin-top: 13px;
}

#EditVATValueBlock {
    display: flex;
    padding-left: 13px;
}

.editVATInputRadio {
    cursor: pointer;
    margin-bottom: 13px;
}

#EditVAT19Block {
    margin-right: 20px;
}

.editVATInputLabel {
    padding-left: 3px;
    cursor: pointer;
    font-family: "Roboto-Medium";
}

#EditVATButtonBlock{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

#CPFooterBlock{
    display:flex;
    cursor: pointer;
}

.contractorRequisitesLinksBlock{
    display: flex;
}
.forminputblock{
    background: white;
}

#addUsersContractors{
    padding: 13px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
#AddUsersContractorsForm{
    flex-grow: 1;
    width: max-content;
    width: 450px;
}
#AddUsersContractorsTitle{
    font-family: "Roboto-Medium";
    display: flex;
    align-items: center;
}
#AddUsersContractorsButtonsBlock{
    display: flex;
    align-items: center;
}
#AddUsersContractorsButtonAdd{
    font-family: "Roboto-Medium";
    cursor: pointer;
    padding: 10px 25px;
    border:1px solid #326FB5;
    color:#326FB5;
    transition: 0.3s;
    background: white;
    border-radius: 5px;
    margin-right: 30px;
}
#AddUsersContractorsButtonAdd:hover{
    color:white;
    background:#326FB5;
}
#AddUsersContractorsButtonBack{
    font-family: "Roboto-Medium";
    cursor: pointer;
    color:#326FB5;
    transition: 0.3s;
    border-radius: 5px;
    opacity: 0.6;
}
#AddUsersContractorsButtonBack:hover{
    opacity: 1;
}
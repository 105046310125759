#tasks{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex-grow: 1;
}
#TasksBlock{
    background:#f7f7f7;
    flex-grow: 1;
    position: relative;
    display: flex;
    padding: 13px;
}
.tasksBoardBlock{
    width: calc(100% / 4);
    background: white;
    height: calc(100% - 26px);
    margin: 0 13px;
    box-shadow: 0px 0px 5px #00000036;
    border-radius: 5px;
    padding: 13px;
    display: flex;
    flex-direction: column;
}
.taskGrayTopLine{
    border-top: 7px solid #BFBFBF;
}
#TaskRedTopLine{
    border-top: 7px solid #DF5252;
}
#TaskYellowTopLine{
    border-top: 7px solid #F2B43F;
}
#TaskGreenTopLine{
    border-top: 7px solid #32B596;
}
.taskGrayText{
    color: #BFBFBF;
    font-family: "Roboto-Regular";
}
.taskRedText{
    color: #DF5252;
    font-family: "Roboto-Regular";
}
.taskYellowText{
    color: #F2B43F; 
    font-family: "Roboto-Regular";
}
.taskGreenText{
    color: #32B596; 
    font-family: "Roboto-Regular";
}
.taskItalicText{
    font-family: "Roboto-Italic";
}
.taskTransactionText{
    font-family: "Roboto-Regular";
    color: #326FB5;
}
.taskBoardHeader{
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: 13px;
    font-family: "Roboto-Medium";
    text-align: center;
}
.taskBoardContentBlock{
    position: relative;
    flex-grow: 1;
    overflow: auto;
    margin-top: 13px;
}
.taskBoardContent{
    position: absolute;
    width: 100%;
    height: 100%;
}
.taskItemBlock{
    background: #afb9c01a;
    border-radius: 5px;
    padding: 10px 20px;
    position: relative;
    margin-bottom: 13px;
    transition: 0.3s;
    cursor: pointer;
}
.taskItemBlock:hover{
    background: #aeb8bf2e;
}
.taskItemTitle{
    font-family: "Roboto-Medium";
    padding: 5px 0;
}
.taskItemClockIcon{
    position: absolute;
    top: 11px;
    left: 3px;
}
.taskLine{
    height: 1px;
    width: 100%;
    background: #e6e6e6;
    margin: 5px 0;
}
.taskSeperator{
    margin: 5px 0;
}
.taskBoardCount{
    margin-top: 13px;
}
.taskResultBlock{
    margin-top: 13px;
    display: flex;
}
.taskResultTitle{
    margin-right: 5px;
}
.taskResultText{
    font-family: "Roboto-Medium";
    word-break: break-all;
}
/* Фильтр */
#TasksFilterBlock{
    font-family: "Roboto-Regular";
    padding: 13px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
}
.tasksFilterInputBlock{
    margin-right: 13px;
    padding: 5px 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    position: relative;
    min-width: 200px;
}
.tasksFilterInputTitle{
    cursor: pointer;
    display: flex;
    align-items: center;
}
.tasksFilterInputArrow{
    position: absolute;
    top: 9px;
    right: 0;
    color:#e0e0e0;
    transition: 0.3s;
}
.tasksFilterContentBlock{
    position: absolute;
    min-width: calc(100% - 26px);
    width: max-content;
    padding: 13px;
    border-radius: 5px;
    background: white;
    z-index: 1000;
    top: 120%;
    left: 0;
    box-shadow: 0px 0px 4px 3px rgb(0 0 0 / 5%);
    display: none;
}
.tasksFilterInputBlock.Active > .tasksFilterContentBlock{
    display: block;
}
.tasksFilterInputBlock.Active > .tasksFilterInputTitle > .tasksFilterInputArrow{
    transform: rotateZ(180deg);
    color:#326FB5;
}
.tasksFilterItemBlock{
    margin-bottom: 10px;
}
.tasksFilterItemBlock:last-child{
    margin-bottom: 0;
}
.tasksFilterInput{
    margin-left: 0;
    margin-right: 0;
    cursor: pointer;
}
.tasksFilterLabel{
    padding-left: 10px;
    cursor: pointer;
}
.tasksFilterInputTitleFilterIcon{
    margin-left: 5px;
}
/* ------ */
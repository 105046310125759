#documentsSettings {
    padding: 13px;
}

#DocumentsSettingsTitle {
    font-family: "Roboto-Medium";
    display: flex;
    align-items: center;
}

.documentsSettingsRadioBlock {
    padding: 13px;
}

.documentsSettingsRadioItem {
    padding-bottom: 13px;
}

.documentsSettingsRadioItemInput {
    cursor: pointer;
}

.documentsSettingsRadioItemLabel {
    cursor: pointer;
}

.documentsSettingsTopImageBlock {
    border-radius: 3px;
    border: 2px solid black;
    height: 198px;
    width: 140px;
    position: relative;
}

.documentsSettingsRadioTwoLevel {
    display: flex;
}

#DocumentsSettingsTopRightImageBlock {
    border-radius: 3px;
    position: absolute;
    width: 40px;
    height: 20px;
    top: 5px;
    right: 5px;
    border: 2px solid salmon;
}

#DocumentsSettingsTopImageBlock {
    border-radius: 3px;
    position: absolute;
    width: 126px;
    height: 20px;
    top: 5px;
    left: 5px;
    border: 2px solid salmon;
}

.documentsSettingsRadioTwoLevelInputLogoBlock {
    margin-left: 26px;
    display: flex;
}

.documentsSettingsPhotoNull {
    width: 32px;
    height: 32px;
    color: #A7A7A7;
    margin-right: 5px;
}

.documentsSettingsImage {
    width: 30mm;
    object-fit: contain;
}

.documentsSettingsText {
    color: #000000;
    font-family: "Roboto-Medium";
    font-size: 14px;
    margin-bottom: 5px;
    white-space: normal;
}

.documentsSettingsRadioTwoLevelInputLogoRemoveIcon{
    color: #A7A7A7;
    font-size: 20px;
    cursor: pointer;
    margin-left: 13px;
}

.documentsSettingsPhotoNullBlock{
    display: flex;
    align-items: center;
    font-family: "Roboto-Regular";
}
#MainSiteBody{
    background: linear-gradient(180deg, #FFFFFF 0%, #D9F0FF 100%);
}
.MSBodyButton{
    background: #A8D1ED;
    outline: none;
    border: none;
    color: #326FB5;
    font-family: "NunitoSans-Bold";
    font-size: 18px;
    transition: all 0.5s linear;
    padding: 20px 50px;
    border-radius: 17px;
    margin-left: auto;
    margin-right: auto;
}
.MSBodyButton:hover{
    color: #FFFFFF;
    background: #336FB5;
}
#MSBodyFirstBlockImage{
    width: 100%;
}
#MSBodyFirstBlockTextButton{
    margin: auto;
    text-align: center;
}
#MSBodyFirstBlockText{
    font-family: "NunitoSans-Regular";
    font-size: 22px;
    color: #444444;
    margin-bottom: 50px;
    margin-left:20px;
    margin-right:20px;
}

#MSBodyFirstBlock{
    padding: 70px 0;
    display: block;
}
#MSBodyFBPCIMG{
    width: 100%;
}
#MSBodyFBMobileIMG{
    width: 100%;
}
#MSBodySecondBlockList{
    display: block;
    justify-content: center;
}
.MSBodySecondBlockItem{
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px 15px rgba(30, 84, 146, 0.08);
    border-radius: 30px;
    margin: 0 20px;
    margin-bottom: 20px;
}
.MSBodySecondBlockItemCircle{
    width: 30px;
    height: 30px;
    padding: 28px;
    border-radius: 50%;
    background: #D9F0FF;
    margin-left: auto;
    margin-right: auto;
}
.MSBodySecondBlockItemCircleIcon{
    width: 30px;
    height: 30px;
}
.MSBodySecondBlockItemTitle{
    margin-top: 25px;
    font-family: "NunitoSans-SemiBold";
    color: #444444;
    font-size: 22px;
    text-align: center;
}
.MSBodySecondBlockItemText{
    color: #727272;
    font-size: 18px;
    margin-top: 25px;
    text-align: center;
}
#MSBodyThirdBlock{
    margin-top: 120px;
    padding-bottom: 120px;
}
#MSBodyThirdBlockTitle{
    margin-left: auto;
    margin-right: auto;
    font-size: 26px;
    text-align: center;
    font-family: "NunitoSans-SemiBold";
    color:#3C3C3C;
    width: 262px;
}
#MSBodyThirdBlockForm{
    margin-top: 55px;
    margin-left: 20px;
    margin-right: 20px; 
    padding: 30px;
    background: white;
    box-shadow: 0px 4px 50px rgba(69, 72, 87, 0.15);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}
.MSBodyThirdBlockFormItem{
    margin-bottom: 30px;
    position: relative;
}
.MSBodyThirdBlockFormInput{
    border: 2px solid #C4C4C4;
    border-radius: 10px;
    padding: 15px;
    outline: none;
    font-size: 30px;
    color: #3C3C3C;
    width: -webkit-fill-available;
}
.MSBodyThirdBlockFormInput:focus{
    border: 2px solid #326FB5;
}
.MSBodyThirdBlockFormLabel{
    position: absolute;
    color: #717173;
    font-size: 18px;
    outline: none;
    left: 10px;
    top: -12px;
    background: white;
    padding: 0 5px;
}
#MSBodyFirstBlockButton{
    width: 297px;
    cursor: pointer;
}
@media (min-width: 1024px) {
    #MSBodyFirstBlockText{
        width: 347px;
        margin: 0;
        margin-bottom: 50px;
    }
    #MSBodyFirstBlockImage{
        width: 90%;
        position: relative;
    }
    #MSBodyFirstBlock{
        padding: 70px 0;
        display: flex;
        flex-direction: row-reverse;
    }
    #MSBodySecondBlockList{
        display: flex;
        flex-wrap: wrap;
    }
    .MSBodySecondBlockItemText{
        width: 290px;
    }
    #MSBodyThirdBlockTitle{
        width: 560px;
        font-size: 29px;
    }
    #MSBodyThirdBlockForm{
        width: 411px;
        margin-left: auto;
        margin-right: auto;
    }
    #MSBodyThirdBlockTitle{
        width: 561px;
    }
  }
.clientinfoblock {
    margin-bottom: 13px;
}

.clientinfotitle {
    color: #A7A7A7;
    font-family: "Roboto-Regular";
    font-size: 14px;
}

.clientinfoinput {
    outline: none;
    border: none;
    padding: 0;
    font-family: "Roboto-Medium";
    font-size: 14px;
    width: 100%;
    padding-bottom: 2px;
}

.clientinfoinput::placeholder {
    color: #D0D0D0;
    font-family: "Roboto-Italic";
    font-size: 14px;
}

#client {
    display: flex;
    width: 100%;
    position: relative;
    flex-grow: 1;
}

#ClientBlock {
    width: 306px;
    position: relative;
    box-shadow: 0px 1px 15px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
}

#ClientBlockContent {
    position: absolute;
    overflow: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

#ClientInfoBlock {
    position: relative;
    padding: 13px;
    padding-bottom: 0;
}

#Clientphotoblock {
    display: flex;
    margin-bottom: 13px;
    align-items: center;
    margin-top: 5px;
}

#ClientPhoto {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

#ClientPhotoNull {
    width: 32px;
    height: 32px;
    color: #A7A7A7;
}

#FIOInput {
    flex-grow: 1;
}

#ClientPhotoInputBlock {
    margin-right: 5px;
}

#ClientPhotoInputLabel {
    cursor: pointer;
}

#LineInfoClient {
    width: calc(100% - 26px);
    height: 1px;
    background: #00000024;
    margin: 0 13px;
}

#ClientTransactionBlock {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 13px;
}

#ClientTransactionListBlock {
    position: relative;
    flex-grow: 1;
    margin-top: 13px;
}

#ClientTransactionList {
    position: absolute;
    width: 100%;
    height: 100%;
}

.clientTransactionCPBlock {
    display: flex;
    margin-bottom: 13px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    background: #F9F9F9;
    border-radius: 5px;
}

.clientTransactionCPButton {
    padding: 3px;
    border: 1px solid #32B596;
    border-radius: 5px;
    font-family: "OpenSans-Regular";
    color: #32B596;
    transition: 0.3s;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
}

.clientTransactionCPButton:hover {
    background: #32B596;
    color: white;
}

.clientTransactionNavLink {
    flex-grow: 1;
}

.clientTransactionBlock {
    border-radius: 7px;
    padding: 13px;
    border-radius: 10px;
    transition: 0.3s;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.clientTransactionBlock:hover {
    background: #f2f2f2;
}

.clientTransactionTitleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clientTransactionTitleCircle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: 0.3s;
}

.clientTransactionTitleCircle.hidden {
    width: 0;
    height: 0;
}

.clientTransactionTitleRedCircle {
    background: #F04A4A;
}

.clientTransactionTitleYellowCircle {
    background: #F2B43F;
}

.clientTransactionTitle {
    color: #326FB5;
    font-family: "Roboto-Bold";
    font-size: 16px;
}

.clientTransactionItem {
    margin-top: 13px;
    font-size: 14px;
    color: #979797;
    font-family: "Roboto-Regular";
}

.clientTransactionItemTaskBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#ClientAddButtonsTransactionCPBlock {
    margin-bottom: 13px;
    display: flex;
}

#ClientAddButtonTransaction {
    margin-right: 5px;
}

.clientAddTransactionValid {
    color: #F34F49;
    display: none;
    font-family: "Roboto-Medium";
    font-size: 14px;
}

.clientAddTransactionValid.Open {
    display: block;
}

#ClientHistoryTasksBlock {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.clientHistoryTasksHeader {
    font-family: "Roboto-Regular";
    margin-left: 20px;
    padding-bottom: 13px;
    color: #A7A7A7;
}

.clientHistoryTasksItemBlock {
    position: relative;
    flex-grow: 1;
}

/* Задачи по клиенту */
#ClientTasks {
    display: flex;
    flex-direction: column;
    padding: 13px;
    position: relative;
    height: 50%;
    border-bottom: 1px solid #e6e6e6;
}

#ClientTasksContent {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: auto;
    box-sizing: border-box;
}

#TaskListBlock {
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    position: absolute;
    overflow: auto;
    padding-right: 13px;
}

.transactionTaskItemBlock {
    width: 100%;
    margin-bottom: 13px;
    border-radius: 5px;
    display: flex;
    padding: 13px;
    font-family: "Roboto-Regular";
    box-sizing: border-box;
}

.taskItem {
    margin-right: 13px;
}

.taskItemInputBlock {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.taskItem:last-child {
    margin-right: 0;
}

.taskWork {
    background: #32B5961A;
    border: 1px solid #32B5961A;
}

.taskToday {
    background: #f0b4001a;
    border: 1px solid #f0b4001a;
}

.taskOverdue {
    background: #df52521a;
    border: 1px solid #df52521a;
}

.taskGreen {
    background: #32B596;
}

.taskYellow {
    background: #F2B43F;
}

.taskRed {
    background: #F04A4A;
}

.taskTextGreen {
    color: #32B596;
}

.taskTextYellow {
    color: #F2B43F;
}

.taskTextRed {
    color: #F04A4A;
}

.taskClockIconBlock {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.taskClockIcon {
    color: white;
}

.taskItemInputTitleBlock {
    display: flex;
}

.taskItemInputTitle {
    font-family: "Roboto-Regular";
}

.taskItemInputTitle:first-child {
    margin-right: 13px;
}

.taskItemInputButtonBlock {
    display: flex;
    align-items: flex-end;
}

.taskItemInput {
    background: white;
    border: 1px solid white;
    outline: none;
    font-family: "Roboto-Regular";
    flex-grow: 1;
    padding: 5px 13px;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 13px;
}

.taskItemInput::placeholder {
    font-family: "Roboto-Italic";
    color: #e6e6e6;
}

.taskItemButton {
    border-radius: 5px;
    height: 26px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    font-family: "OpenSans-Regular";
    transition: 0.3s;
    cursor: pointer;
}

.taskItemButtonRed {
    border: 1px solid #F04A4A;
    color: #F04A4A;
}

.taskItemButtonRed:hover {
    border: 1px solid #F04A4A;
    color: white;
    background: #F04A4A;
}

.taskItemButtonYellow {
    border: 1px solid #F2B43F;
    color: #F2B43F;
}

.taskItemButtonYellow:hover {
    border: 1px solid #32B596;
    color: white;
    background: #32B596;
}

.taskItemButtonGreen {
    border: 1px solid #32B596;
    color: #32B596;
}

.taskItemButtonGreen:hover {
    border: 1px solid #32B596;
    color: white;
    background: #32B596;
}

/* ----------------- */

/* История по клиенту */

#ClientHistory {
    display: flex;
    flex-direction: column;
    padding: 13px;
    position: relative;
    height: 50%;
}

#ClientHistoryContent {
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    position: absolute;
    overflow: auto;
    padding-right: 13px;
}

#ClientHistoryContent:has {
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    position: absolute;
    overflow: auto;
    padding-right: 13px;
}

#ClientHistoryInputBlock {
    height: 105px;
    padding: 13px;
    display: flex;
    flex-direction: column;
}

#ClientHistoryInputArea {
    padding: 10px;
    border: 1px solid #00000020;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    flex-grow: 1;
    border-top: 1px solid #326FB5;
}

.ClientHistoryInputTitleBlock {
    display: flex;
}

.ClientHistoryInputTitle {
    color: #326FB5;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: max-content;
    font-family: "Roboto-Regular";
    font-size: 14px;
    transition: 0.3s;
}

.ClientHistoryInput {
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-family: "Roboto-Regular";
}

.ClientHistoryInput::placeholder {
    font-family: "Roboto-Italic";
    font-size: 14px;
}

.ClientHistoryInputTitle.Active {
    color: white;
    background: #326FB5;
}

.ClientHistoryInputAddButton {
    outline: none;
    background: white;
    color: #326FB5;
    border: 1px solid #326FB5;
    border-radius: 5px;
    padding: 5px 10px;
    font-family: "Roboto-Regular";
    margin-top: 5px;
    cursor: pointer;
    transition: 0.3s;
    width: max-content;
    font-size: 12px;
}

.ClientHistoryInputAddButton:hover {
    color: white;
    background: #326FB5;
}

.ClientHistoryClassNote {
    display: flex;
    background: #ffc55959;
    border-radius: 10px;
    padding: 13px;
    margin-bottom: 13px;
    color: #F2B43F;
}

.ClientHistoryClassEdit {
    display: flex;
    background: white;
    border-radius: 10px;
    padding: 0 13px;
    color: #326FB5;
    align-items: center;
}

.ClientHistoryIcon {
    margin-right: 13px;
    width: 24px;
    height: 24px;
}

.ClientHistoryBorder {
    border-right: 2px solid #326FB5;
    width: 2px;
    height: 50px;
    margin-right: 13px;
    margin-left: 20px;
    position: relative;
}

.ClientHistoryBorderPoint {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #326FB5;
    top: calc(50% - 5px);
    right: -6px;
    border-radius: 50%;
}

.ClientHistoryDate {
    margin-right: 13px;
    color: inherit;
    font-family: "Roboto-Regular";
    font-size: 14px;
    line-height: 24px;
}

.ClientHistoryPeople {
    color: black;
    font-family: "Roboto-Bold";
    font-size: 14px;
    line-height: 24px;
}

.ClientHistoryBlock {
    display: flex;
    align-items: center;
}

.ClientHistoryText {
    margin-left: 13px;
    font-family: "Roboto-Regular";
    color: black;
    font-size: 14px;
    line-height: 24px;
}

.ClientHistoryNote {
    font-family: "Roboto-Italic";
    font-size: 14px;
    color: #00000067;
}

.clientHistoryContentItem {
    position: relative;
}

.clientHistoryDateBlock {
    border-radius: 20px;
    background: #eaf0f7;
    padding: 5px 10px;
    margin: 0 auto;
    width: max-content;
    color: #326FB5;
    position: sticky;
    top: 0px;
    margin-bottom: 13px;
}

.clientHistoryTextChangValueBlock {
    display: flex;
    align-items: center;
}

.clientHistoryTextChangValueArrow {
    color: #A7A7A7;
}

.clientHistoryBoldText {
    color: black;
    font-family: "Roboto-Bold";
    font-size: 14px;
    line-height: 24px;
}

.transactionHistoryLinkBlock {
    display: flex;
    align-items: center;
    line-height: 24px;
    color: black;
    font-size: 14px;
    font-family: "Roboto-Regular";
    margin-left: 13px;
}

.transactionHistoryText {
    margin-right: 5px;
}

.taskTransactionTitle {
    margin-bottom: 5px;
}

#ClientInfoTitleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#ClientInfoTitleBlockDeleteIcon {
    border-radius: 5px;
    transition: 0.3s;
    cursor: pointer;
    font-size: 16px;
    color: #a7a7a7;
    background: #e6e6e666;
    padding: 3px;
}

#ClientInfoTitleBlockDeleteIcon.Open {
    display: none;
}

#ClientInfoTitleBlockDeleteIcon:hover {
    color: black;
    background: #e6e6e6;
}

#ClientBlockDelete {
    padding: 5px 0;
    border: 1px dashed #a7a7a7;
    border-radius: 5px;
    padding: 5px;
    display: none;
}

#ClientBlockDelete.Open {
    display: block;
}

#ClientConfirmRemoveButtonsBlock {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
}

#ClientConfirmRemoveText {
    font-family: "Roboto-Regular";
}

.clientTransactionItemTaskColorBlock {
    padding: 3px 12px;
    border-radius: 9px;
}

.clientTransactionItemTaskYellow {
    background: #FFF2D9;
    color: #F2B43F;
}

.clientTransactionItemTaskRed {
    background: #FFE4E4;
    color: #FC4141;
}

.clientTransactionItemTaskGreen {
    background: #E4FFF4;
    color: #73D28E;
}

.clientTransactionItemTaskGray {
    background: #EAEAEA;
    color: #A7A7A7;
}

.clientModalBlock {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;
    align-items: center;
    justify-content: center;
}

.clientModalBlock.Open {
    display: flex;
}

#RequisitesModal {
    width: 500px;
    height: max-content;
    background: white;
    border-radius: 5px;
    padding: 20px;
}

#RequisitesModalHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

#RequisitesModalCancel {
    cursor: pointer;
    background: #DDEFFD;
    transition: 0.3s;
    font-size: 14px;
    padding: 10px 20px;
    color: #326FB5;
    margin-right: 13px;
    border-radius: 5px;
    font-family: "OpenSans-Regular";
}

#RequisitesModalCancel:hover {
    background: #cce5fa;
}

#RequisitesModalHeaderTitle {
    font-family: "OpenSans-Bold";
    color: black;
}

.formclientinputvalid {
    position: inherit;
}

#RequisitesClientButton {
    font-family: "Roboto-Medium";
    cursor: pointer;
    padding: 15px 13px;
    background: #DDEFFD;
    color: #326FB5;
    transition: 0.3s;
    font-size: 16px;
    width: max-content;
    width: calc(100% - 26px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#RequisitesClientButton:hover {
    color: white;
    background: #326FB5;
}

#ClientInfoBlockDetalization {
    max-height: 0;
    transition: 0.3s;
    overflow: hidden;
}

#ClientInfoBlockDetalization.Open {
    max-height: 350px;
}

#ClientInfoBlockDetalizationButton {
    transition: 0.3s;
    color: #a7a7a7;
    cursor: pointer;
    border-radius: 5px;
    padding: 3px 5px;
    transform: rotateZ(-90deg);
}

#ClientInfoBlockDetalizationButton:hover {
    background: #e6e6e666;
    color: black;
}

#ClientInfoBlockDetalizationButton.Open {
    transform: rotateZ(90deg);
}

.clientTransactionDetalizationButton {
    padding: 13px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: rotateZ(-90deg);
    transition: 0.3s;
    color: #a7a7a7;
}

.clientTransactionDetalizationButton:hover {
    color: black;
}

.clientTransactionDetalizationButton.Open {
    transform: rotateZ(90deg);
}

.clientTransactionDetalization {
    max-height: 0;
    transition: all 0.3s linear;
    overflow: hidden;
}

.clientTransactionDetalization.Open {
    max-height: 150px;
    transition: all 0.3s linear;
}

#ClientContactPersonalsBlock {
    padding: 13px;
    padding-bottom: 0;
}

#ClientContactPersonalsTitle {
    margin-bottom: 13px;
}

#RequisitesClientIndividual {
    cursor: pointer;
}

#RequisitesClientIndividualLabel {
    cursor: pointer;
}

#RequisitesClientContactNamesArrowIcon {
    padding: 15px 13px;
    cursor: pointer;
    border: 1px solid #AFB8BF;
    border-radius: 5px;
    margin-left: 2px;
    color: #AFB8BF;
}

#RequisitesClientContactNamesArrowIcon.Open {
    transform: rotateZ(180deg);
    border: 1px solid #326FB5;
    color: #326FB5;
}

#RequisitesClientContactNamesBlock {
    display: flex;
    align-items: center;
    position: relative;
}

#RequisitesClientContactNamesListBlock {
    position: absolute;
    top: 63px;
    padding: 10px 7px;
    border-radius: 7px;
    z-index: 1;
    background: white;
    max-height: 90px;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    display: none;
    box-shadow: 0 0 10px #AFB8BF;
}

#RequisitesClientContactNamesListBlock.Open {
    display: block;
}

.requisitesClientContactNameItem {
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 7px;
}
.requisitesClientContactNameItem:hover {
    background: #afb8bf5a;
}

/* .requisitesClientContactNameItem:first-child {
    padding-top: 0;
}

.requisitesClientContactNameItem:last-child {
    padding-bottom: 0;
} */
#transactionblock{
    display: flex;
    flex-grow: 1;
}
.transactiontitle{
    color: #A7A7A7;
    font-family: "Roboto-Regular";
    font-size: 12px;
    margin-bottom: 3px;
}
.transactiondata{
    color: #000000;
    font-family: "Roboto-Medium";
    font-size: 14px;
}
.transactioninput{
    background: transparent;
    outline: none;
    border:none;
    padding: 0;
    width: 100%;
}
#TransactionDataBlock{
    height: 100%;
    width: 300px;
    padding: 10px;
    box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
#TransactionDataBlockHeader{
    padding: 10px;
    border-radius: 5px;
    background: #326FB5;
    margin-bottom: 13px;
    display: flex;
}
#TransactionDataBlockHeaderContent{
    flex-grow: 1;
}
#TransactionDataBlockHeaderTitleBlock{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
#TransactionDataBlockHeaderTitle{
    font-family: "OpenSans-Bold";
    font-size: 16px;
    color: white;
    flex-grow: 1;
}
#TransactionDataBlockHeaderTitleDeleteBlock{
    position: relative;
}
#TransactionDataBlockHeaderTitleDeleteIcon{
    padding: 3px;
    background: #ffffff26;
    border-radius: 5px;
    display: flex;
    transition: 0.3s;
    cursor: pointer;
    font-size: 16px;
    color: white;
}
#TransactionDataBlockHeaderTitleDeleteIcon:hover{
    background: #ffffff46;
}
#TransactionDataBlockHeaderTitleConfirmDeleteBlock{
    color: black;
    background: white;
    padding: 13px;
    border-radius: 7px;
    position: absolute;
    left: 0;
    top:23px;
    width: max-content;
    z-index: 2;
    display: none;
    font-family: "Roboto-Regular";
    flex-direction: column;
    align-items: flex-end;
    box-shadow: 5px 0px 15px rgb(0 0 0 / 10%);
}
#TransactionDataBlockHeaderTitleConfirmDeleteBlock.Open{
    display: flex;
}
#TransactionDataBlockHeaderTitleConfirmDeleteButtonsBlock{
    display: flex;
    margin-top: 5px;
}
.TransactionDataBlockHeaderTitleConfirmDeleteButton{
    padding: 5px 10px; 
    border-radius: 7px;
    color: white;
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.TransactionDataBlockHeaderTitleConfirmDeleteButton:hover{
    opacity: 1;
}
#TransactionDataBlockHeaderTitleConfirmDeleteYes{
    background: #32B596;
    margin-right: 10px;
}
#TransactionDataBlockHeaderTitleConfirmDeleteNo{
    background: #F04A4A;
}
#TransactionDataBlockHeaderNumber{
    font-family: "Roboto-Regular";
    font-size: 12px;
    color: #ffffff67;
    margin-bottom: 5px;
}
#TransactionDataBlockHeaderProgressBlock{
    border-radius: 5px;
    padding: 10px;
    background: #ffffff1a;
    position: relative;
    cursor: pointer;
}

#TransactionDataBlockHeaderProgressTitleBlock{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    color: white;
}
#TransactionDataBlockHeaderProgressTitle{
    font-family: "Roboto-Regular";
    font-size: 12px;
}
#TransactionDataBlockHeaderProgressTitleIcon{
    transition: 0.3s;
}
#TransactionDataBlockHeaderProgressTitleIcon.Open{
    transform: rotateZ(180deg);
}
#TransactionDataBlockHeaderProgressStepBlock{
    position: absolute;
    background: white;
    padding: 6px 4px;
    width: calc(100% - 10px);
    left: 0;
    border-radius: 5px;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 10%);
    top: 44px;
}
.TransactionDataBlockHeaderProgressStep{
    font-family: "Roboto-Regular";
    font-size: 14px;
    padding: 3px;
    transition: 0.3s;
    border-radius: 5px;
}
.TransactionDataBlockHeaderProgressStep:hover{
    background: #a7a7a741;
}
#TransactionDataBlockHeaderProgress{
    background: #1E5492;
    border-radius: 10px;
    height: 4px;
}
#TransactionDataBlockHeaderProgressValue{
    background: #F2B43F;
    height: 4px;
    border-radius: 10px;
    transition: 0.3s;
}
.TransactionDataBlockHeaderBlock{
    margin-bottom: 13px;
    cursor: default;
}
#TransactionDataBlockHeaderLine{
    opacity: 0.2;
    margin-bottom: 13px;
}
#TransactionDataBlockHeaderPhotoClient{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}
#TransactionDataBlockHeaderClientBlock{
    display: flex;
}
#TransactionDataBlockHeaderClientNameBlock{
    transition: 0.3s;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 7px;
    border-radius: 5px;
    background: #a7a7a72b;
    flex-grow: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
#TransactionDataBlockHeaderClientNameBlock:hover{
    background: #a7a7a756;
}
#TransactionDataBlockHeaderClientNameButton{
    color: #a7a7a7;
    border-radius: 5px;
    background: #a7a7a72b;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    border-left: 1px dashed #a7a7a7;
    display: flex;
    align-items: center;
    padding: 0 7px;
}
#TransactionDataBlockHeaderClientNameButton:hover{
    background: #a7a7a756;
}
#TransactionDataBlockHeaderClientNameButton.Open > #TransactionDataBlockHeaderClientNameButtonArrow{
    transform: rotateZ(180deg);
}
#TransactionDataBlockHeaderClientNameButtonArrow{
    transition: all 0.3s linear;
} 
#TransactionClientDataDetalization{
    max-height: 0;
    transition: all 0.5s linear;
    overflow: hidden;
}
#TransactionClientDataDetalization.Open{
    max-height: 300px;
}
#TransactionClientDataDetalizationBottomLine{
    border-bottom: 1px dashed #a7a7a7;
    margin-bottom: 13px;
}
#TransactionClietSelectBlock{
    position: relative;
}
#TransactionClietSelectButtonBlock{
    color: #000000;
    font-family: "Roboto-Medium";
    font-size: 14px;
    cursor: pointer;
    padding: 10px;
    background: #a7a7a72d;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#TransactionClietSelectButtonBlockIcon{
    transition: 0.3s;
}
#TransactionClietSelectButtonBlockIcon.Open{
    transform: rotateZ(180deg);
}
#TransactionClietSelectClientsBlock{
    position: absolute;
    background: white;
    padding: 6px 4px;
    width: calc(100% - 10px);
    left: 0;
    border-radius: 5px;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 10%);
    height: 300px;
    overflow: auto;
}
.TransactionClietSelectClients{
    padding: 5px;
    transition: 0.3s;
    cursor: pointer;
    color: #000000;
    font-family: "Roboto-Medium";
    font-size: 12px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}
.TransactionClietSelectClients:hover{
    background: #a7a7a760;
}
.TransactionClietSelectClientsPhoto{
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}


/* История по сделке */
#TransactionHistoryBlock{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
#TransactionHistoryInputBlock{
    height: 105px;
    padding: 13px;
    display: flex;
    flex-direction: column;
}
#TransactionHistory{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 13px;
    position: relative;
    padding-bottom: 0;
}
#TransactionHistoryInputArea{
    padding: 10px;
    border: 1px solid #00000020;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    flex-grow: 1;
    border-top: 1px solid #326FB5;
}
.TransactionHistoryInputTitleBlock{
    display: flex;
}
.TransactionHistoryInputTitle{
    color: #326FB5;
    padding: 5px 10px;
    display:flex;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: max-content;
    font-family: "Roboto-Regular";
    font-size: 14px;
    transition: 0.3s;
    cursor: pointer;
}
.TransactionHistoryInput{
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-family: "Roboto-Regular";
}
.TransactionHistoryInput::placeholder{
    font-family: "Roboto-Italic";
    font-size: 14px;
}
.TransactionHistoryInputTitle.Active{
    color: white;
    background: #326FB5;
}
.transactionFooterInputAddButton{
    outline: none;
    background: white;
    color: #326FB5;
    border:1px solid #326FB5;
    border-radius: 5px;
    padding: 5px 10px;
    font-family: "Roboto-Regular";
    margin-top: 5px;
    cursor: pointer;
    transition: 0.3s;
    width: max-content;
    font-size: 12px;
}
.transactionFooterInputAddButton:hover{
    color : white;
    background: #326FB5;
}
.TransactionHistoryClassNote{
    display: flex;
    background: #ffc55959;
    border-radius: 10px;
    padding: 13px;
    margin-bottom: 13px;
    color: #F2B43F;
}
.TransactionHistoryClassEdit{
    display: flex;
    background: white;
    border-radius: 10px;
    padding: 0 13px;
    margin-bottom: 0px;
    color: #326FB5;
    align-items: center;
}
.TransactionHistoryClassEdit + .TransactionHistoryClassNote{
    margin-top: 13px;
}
.TransactionHistoryIcon{
    margin-right: 13px;
    width: 24px;
    height: 24px;
}
.TransactionHistoryBorder{
    border-right: 2px solid #326FB5;
    width: 2px;
    height: 50px;
    margin-right: 13px;
    margin-left: 20px;
    position: relative;
}
.TransactionHistoryBorderPoint{
    position: absolute;
    width: 10px;
    height: 10px;
    background: #326FB5;
    top: calc(50% - 5px);
    right: -6px;
    border-radius: 50%;
}
.transactionHistoryDateTime{
    margin-right: 13px;
    color: inherit;
    font-family: "Roboto-Regular";
    font-size: 14px;
    line-height: 24px;
}
.TransactionHistoryPeople{
    color: black;
    font-family: "Roboto-Bold";
    font-size: 14px;  
    line-height: 24px;
}
.TransactionHistoryBlock{
    display: flex;
    align-items: center;
}
.TransactionHistoryText{
    margin-left: 13px;
    font-family: "Roboto-Regular";
    color:black;
    font-size: 14px;  
    line-height: 24px;
}
.TransactionHistoryNote{
    font-family: "Roboto-Italic";
    font-size: 14px;  
    color: #00000067;
}
#TransactionHistoryContent{
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    position: absolute;
    overflow: auto;
    padding-right: 13px;
}
#TransactionHistoryContent:has{
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    position: absolute;
    overflow: auto;
    padding-right: 13px;
}
.transactionHistoryItem{
    height: 50%;
    display: flex;
    flex-direction: column;
}
.transactionHistoryItem:first-child{
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 13px;
}
.transactionHistoryItem:first-child > .transactionHistoryItemContent{
    margin-left: 20px;
}
.transactionHistoryItemHeader{
    font-family: "Roboto-Regular";
    margin-left: 20px;
    padding-bottom: 13px;
    color: #A7A7A7;
}
.transactionHistoryItemContent{
    flex-grow: 1;
    position: relative;
}
.TransactionHistoryContentItem{
    position: relative;
}
.TransactionHistoryDateBlock{
    border-radius: 20px;
    background: #eaf0f7;
    padding: 5px 10px;
    margin: 0 auto;
    width: max-content;
    color: #326FB5;
    position: sticky;
    top: 0px;
    margin-bottom: 13px;
}
.TransactionHistoryTextChangValueBlock{
    display: flex;
    align-items: center;
}
.TransactionHistoryTextChangValueArrow{
    color: #A7A7A7;
}
#TransactionTaskAddBlock{
    display: flex;
    align-items: center;
}
.transactionTaskAddInputBlock{
    position: relative;
}
.transactionTaskAddInputBlock:last-child{
    flex-grow: 1;
}
.transactionTaskAddLabel{
    position: absolute;
    margin-left: 8px;
    color: #F34F49;
    font-family: "Roboto-Italic";
    font-size: 14px;
    display: none;
}
.transactionTaskAddLabel.Open{
    display: block;
}
#TransactionTaskDateTimeInput{
    outline: none;
    border: 1px solid #326FB5;
    color: #326FB5;
    border-radius: 5px;
    margin: 0 5px;
}
.transactionTaskAddText{
    color:#BFBFBF;
    font-family: "Roboto-Italic";
}
#TransactionTaskAddManagerText{
    color:#326FB5;
    font-family: "Roboto-Italic";
    margin: 0 5px;
    cursor: pointer;
}
#TransactionTaskAddTitleInput{
    outline: none;
    width: calc(100% - 15px);
    border: none;
    margin: 0 5px;
    font-family: "Roboto-Medium";
    font-size: 14px;
}
#TransactionTaskAddTitleInput::placeholder{
    font-family: "Roboto-Italic";
    color:#BFBFBF;
}
#TaskListBlock{
    width: calc(100% - 26px);
    height: 95%;
    position: absolute;
    overflow: auto;
    padding-right: 13px;
}
.transactionTaskItemBlock{
    width: calc(100% - 26px);
    margin-bottom: 13px;
    border-radius: 5px;
    display: flex;
    padding: 13px;
    font-family: "Roboto-Regular";
}
.taskItem{
    margin-right: 13px;
}
.taskItemInputBlock{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.taskItem:last-child{
    margin-right: 0;
}
.taskWork{
    background: #32B5961A;
    border: 1px solid #32B5961A;
}
.taskOverdue{
    background: #df52521a;
    border: 1px solid #df52521a; 
}
.transactionTaskItemBlock.taskOverdue.taskFocuse{
    border: 1px solid #F04A4A; 
}
.taskToday{
    background: #f0b4001a;
    border: 1px solid #f0b4001a; 
}
.transactionTaskItemBlock.taskToday.taskFocuse{
    border: 1px solid #F2B43F; 
}
.transactionTaskItemBlock.taskWork.taskFocuse{
    border: 1px solid #32B596; 
}
.taskGreen{
    background: #32B596;
}
.taskYellow{
    background: #F2B43F;
}
.taskRed{
    background: #F04A4A;
}
.taskTextGreen{
    color: #32B596; 
}
.taskTextYellow{
    color: #F2B43F; 
}
.taskTextRed{
    color: #F04A4A; 
}
.taskClockIconBlock{
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.taskClockIcon{
   color: white;
}
.taskItemInputTitleBlock{
    display: flex;
}
.taskItemInputTitle{
    font-family: "Roboto-Regular";
}
.taskItemInputTitle:first-child{
    margin-right: 13px;
}
.taskItemInputButtonBlock{
    display: flex;
    align-items: flex-end;
}
.taskItemInput{
    background: white;
    border: 1px solid white;
    outline: none;
    font-family: "Roboto-Regular";
    flex-grow: 1;
    padding: 5px 13px;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 13px;
}
.taskItemInput::placeholder{
    font-family: "Roboto-Italic";
    color: #e6e6e6;
}
.taskItemButton{
    border-radius: 5px;
    height: 26px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    font-family: "OpenSans-Regular";
    transition: 0.3s;
    cursor: pointer;
}
.taskItemButtonRed{
     border: 1px solid #F04A4A;
     color: #F04A4A;
}
.taskItemButtonRed:hover{
    border: 1px solid #F04A4A;
    color: white;
    background: #F04A4A;
}
.taskItemButtonYellow{
    border: 1px solid #F2B43F;
    color: #F2B43F;
}
.taskItemButtonYellow:hover{
    border: 1px solid #F2B43F;
    color: white;
    background: #F2B43F;
}
.taskItemButtonGreen{
    border: 1px solid #32B596;
    color: #32B596;
}
.taskItemButtonGreen:hover{
    border: 1px solid #32B596;
    color: white;
    background: #32B596;
}
#TransactionTaskAddManagerBlock{
    position: relative;
}
#TransactionTaskAddManagerList{
    position: absolute;
    background: white;
    border-radius: 5px;
    padding: 5px;
    top: -115px;
    height: 100px;
    padding: 5px 13px;
    box-shadow: 0px 0px 15px 1px rgb(0 0 0 / 10%);
    overflow: auto;
    display: none;
}
#TransactionTaskAddManagerList.Open{
    display: block;
}
.transactionAddTaskManagerItem{
    font-family: "OpenSans-Regular";
    padding: 5px;
    transition:0.3s;
    border-radius: 5px;
    cursor: pointer;
}
.transactionAddTaskManagerItem:hover{
    background: #e6e6e6;
}
#TransitionTaskPostponeModalBlock{
    position: fixed;
    background: rgba(167, 167, 167, 0.445);
    width: 100%;
    height: 100%;
    z-index: 2000;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    display: none;
}
#TransitionTaskPostponeModalBlock.Open{
    display: flex;
}
#TransitionTaskPostponeModal{
    background: white;
    width:600px;
    top: 0;
    left: 0;
    border-radius: 5px;
    padding: 13px;
}
#TransitionTaskPostponeModalButtonsBlock{
    justify-content: space-between;
    align-items: center;
    display: flex;
}
.transitionTaskPostponeModalRowBlock{
    display: flex;
    align-items: center;
}
.transitionTaskPostponeModalInputBlock{
    margin-top: 13px;
}
.transitionTaskPostponeModalLabel{
    color: #a7a7a7;
    font-family: "Roboto-Regular";
}
#TransitionTaskPostponeModalTitleTask{
    color: black;
    font-family: "Roboto-Medium";
    margin-left: 13px;
}
#TransitionTaskPostponeModalDate{
    outline: none;
    border: 1px solid #e6e6e6;
    border-radius: 5px;   
    font-family: "Roboto-Regular";
    padding: 5px;
}
#TransitionTaskPostponeModalTextArea{
    resize: vertical;
    width: calc(100% - 26px);
    outline: none;
    border: 1px solid #e6e6e6;
    border-radius: 5px;   
    font-family: "Roboto-Regular";
    padding: 13px;
}
#TransitionTaskPostponeModalSuccessButton{
    color:#326FB5;
    border:1px solid #326FB5;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 20px;
    font-family: "Roboto-Medium";
}
#TransitionTaskPostponeModalSuccessButton:hover{
    color: white;
    background: #326FB5;
}
#TransitionTaskPostponeModalCancelButton{
    color:#e6e6e6;
    transition: 0.3s;
    cursor: pointer;
    font-family: "Roboto-Medium";
}
#TransitionTaskPostponeModalCancelButton:hover{
    color:#326FB5;
}
.transitionTaskPostponeModalValidText{
    color: #F34F49;
    display: none;
    font-family: "Roboto-Medium";
    font-size: 14px;
}
.transitionTaskPostponeModalValidText.Invalid{
    display: block;
}
.taskTime{
    cursor: pointer;
}
.taskTime:hover{
  border-bottom: 1px dashed ;
}
#TransactionCPBLock{
    position: relative;
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
#TransactionCPContent{
    width: 100%;
    height: 100%;
    padding-right: 13px;
    overflow: auto;
    position: absolute;
}
.cpBlockMain{
    margin-bottom: 13px;
}
.cpBlock{
    padding: 13px;
    background: #a7a7a72b;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
}
.cpBlock.Check{
    background: #74550f2b;
}
.cpBlock:last-child{
    margin-bottom: 0;
}
.cpBlock:hover{
    background: #a7a7a738;
}
.cpBlock.Check:hover{
    background: #74560f38;
}
.cpBlock.Active{
    background: #326fb5a3!important;
}
.cpTitleText{
    color: #A7A7A7;
    font-family: "Roboto-Regular";
    font-size: 12px;
}
.cpPriceBlock{
    display: flex;
    align-items: center;
}
.cpText{
    font-family: "Roboto-Medium";
    color: black;
}
.cpPrice{
    margin-left: 5px;
    font-family: "Roboto-Medium";
    color: black;
}
#TransactionAddCPButton{
    margin-bottom: 13px;
}
.cpFullBlockMain > .cpBlockMain:first-child{
    margin-bottom: 0;
}
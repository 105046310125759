#Transactions{
    position: relative;
    background: #f6f6f6;
    overflow: auto;
    display: flex;
    padding: 13px;
    flex-grow: 1;
}
#TransactionsContent{
    position: absolute;
    height: calc(100% - 26px);
    overflow: auto;
    /* white-space: nowrap; */
    padding-right: 13px;
    display: flex;
}

/* Блок удаления */
#BoardConfirmRemoveBlock{
    background: white;
    padding: 5px;
    border-radius: 5px;
    display: none;
    font-family: "Roboto-Regular";
    flex-direction: column;
    align-items: flex-end;
}
#BoardConfirmRemoveBlock.Open{
    display: flex;
}
#BoardConfirmRemoveButtonsBlock{
    margin-top: 5px;
    display: flex;
}
.boardConfirmRemoveButton{
    padding: 5px 10px; 
    border-radius: 7px;
    color: white;
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.boardConfirmRemoveButton:hover{
    opacity: 1;
}
.BoardConfirmButtonYes{
    background: #32B596;
    margin-right: 10px;
}
.BoardConfirmButtonNo{
    background: #F04A4A;
}
/*  */

.boardBlock{
    /* display: inline-block; */
    height: 100%;
    margin-right: 13px;
}
.board{
    padding: 8px 0px;
    width: 240px;
    font-size: 14px;
    border-radius: 5px;
    font-family: "Roboto-Medium";
    display: flex;
    flex-direction: column;
    cursor: pointer;
    max-height: calc(100% - 20px);
    transition: 0.3s;
}
.board:last-child{
    margin-right: 0px;

}
.boardTransactionsBlock{
    overflow: auto;
    padding: 0 5px;
    margin: 0 5px;
}
.boardTransactionsContentBlock{
    width: 100%;
    height: 100%;
    overflow: auto;
    position: absolute;
    padding-right: 5px;
}

.boardTitle{
    margin: 0 10px;
    margin-bottom: 10px;
}
.boardTitleBudget{
    margin: 5px 0;
    color: black;
    margin-left: 2px;
}

.boardTitleBlock{
    margin-bottom: 5px;
}
.boardTitleText{
    flex-grow: 1;
    padding: 2px 2px;
    cursor: pointer;
}
.boardTitleInput{
    background: transparent;
    outline: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    cursor: text;
    padding: 0;
    border-radius: 5px;
    border:2px solid transparent;
    flex-grow: 1;
}
.boardTitleInput:focus{
    background: white;
}

.boardTransaction{
    padding: 10px;
    width: calc(100% - 20px);
    font-size: 14px;
    font-family: "Roboto-Medium";
    margin-right: 10px;
    margin-bottom: 10px;
    background: white;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.boardTransaction:hover{
    background: #ececec;
}

.boardTitleRemoveBlock{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.boardRemoveIcon{
    padding: 3px;
    border-radius: 5px;
    transition: 0.3s;
    cursor: pointer;
    font-size: 16px;
    opacity: 0;
}
.board:hover > .boardTitle > .boardTitleRemoveBlock > .boardRemoveIcon{
    opacity: 1;
}
.boardRemoveIcon:hover{
    background: #00000012;
}

.transactionTitle{
    color: #326FB5;
    font-size: 14px;
    font-family: "Roboto-Bold";
    white-space: normal;
}
.transactionline{
    height: 2px;
    background: #A7A7A7;
    opacity: 0.2;
    margin: 5px 0;
}
.transactionsTitle{
    color: #A7A7A7;
    font-family: "Roboto-Regular";
    font-size: 12px;
    margin-bottom: 3px;
}
.transactionsData{
    color: #000000;
    font-family: "Roboto-Medium";
    font-size: 14px;
    margin-bottom: 5px;
    white-space: normal;
}
.transactionsData:last-child{
    margin-bottom: 0;
}

/* Добавление доски */
#BoardNewBlock{
    display: inline-block;
    width: 220px;
    background: #a7a7a71a;
    border-radius: 5px;
}
#BoardNew{
    padding: 6.5px 0;
    color: #A7A7A7;
    padding: 9px 10px;
    height: 14px;
    font-size: 12px;
    font-family: "Roboto-Medium";
    display: flex;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 5px;
}
#BoardNew.Close{
    display: none;
}
#BoardNew:hover{
    color: white;
    background: #A7A7A7;
}
#BoardNewPlus{
    margin-right: 15px;
}

#BoardNewForm{
    padding: 8px 10px;
    display: none;
}
#BoardNewForm.Open{
    display: block;
}
#BoardNewForm>input{
    width: 190px;
    outline: none;
    border: none;
    padding: 5px;
    border-radius: 5px;
    border:2px solid transparent
}
#BoardNewForm>input:focus{
    border:2px solid #326FB5
}
.boardNewFormIcon{
    color: #A7A7A7;
    transition: 0.3s;
    margin-left: 5px;
    cursor: pointer;
}
.boardNewFormIcon:hover{
    color: #326FB5;
}
#BoardNewFormColorsBlock{
    display: flex;
    padding: 10px 4px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.boardNewFormColor{
    width: 20px;
    height: 20px;
    font-size: 14px;
    border-radius: 50%;
    cursor: pointer;    
    transition: 0.3s;
    color: transparent;
    align-items: center;
    display: flex;
    justify-content: center;
}
.boardNewFormColor:hover{
    opacity: 0.7;
}
.boardNewFormColor.Select{
    color: white;
}
#BoardNewFormButtonsBlock{
    display: flex;
    align-items: center;
}
#BoardNewFormButtonAdd{
    background: #326FB5;
    opacity: 0.8;
    margin-right: 5px;
    padding: 5px 10px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}
#BoardNewFormButtonAdd:hover{
    opacity: 1;
}
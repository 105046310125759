#AddWorksItemsMainBlock{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
#AddWorksItemsBlock{
    padding: 13px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
#AddWorksItemsBackIcon{
    position: absolute;
    left: 0;
    top: 15px;
    color: #326FB5;
    cursor: pointer;
}
#AddWorksItemsPhotoListBlock{
    flex-grow: 1;
    overflow: auto;
    position: relative;
}
#AddWorksItemsPhotoListContentBlock{
    width: calc(100% - 10px);
    height: 100%;
    overflow: auto;
    position: absolute;
    padding-right: 10px;
}
#AddWorksItemsTitle{
    font-size: 14px;
    font-family: "OpenSans-Bold";
}
#AddWorksItemsFotoFixedBlock{
    display: flex;
    align-items: center;
    font-size: 14px;
}
#AddWorksItemsFotoFixedBlock > label, #AddWorksItemsFotoFixedBlock > input{
    cursor: pointer;
}
#AddWorksItemsProfessionsBlock{
    display: flex;
    flex-wrap: wrap;
    margin-top: 13px;
    align-items: center;
}
.AddWorksItemsAddButton{
    cursor: pointer;
    transition: 0.3s;
    color: #A7A7A7;
    font-family: "OpenSans-Regular";
    margin-top: 13px;
    margin-bottom: 13px;
    width: max-content;
    height: 26px;
}
.AddWorksItemsAddButton:first-child{
    margin-bottom: 0;
}
.AddWorksItemsAddButton:hover{
    color:black;
}
#AddWorksItemsPhotoCancelButton{
    cursor: pointer;
    transition: 0.3s;
    color: #326FB5;
    font-family: "OpenSans-Regular";
    width: max-content;
    margin-left: 20px;
    opacity: 0.6;
}
#AddWorksItemsPhotoCancelButton:hover{
    opacity: 1;
}
.AddWorksItemsPhotoBlock{
    margin-top: 13px;
    display: flex;
    padding: 13px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
}
.AddWorksItemsPhotoBlock:first-child{
    margin-top: 0;
}
.AddWorksItemsPhotoTextBlock{
    position: relative;
    flex-grow: 1;
    margin-left: 13px;
}
.AddWorksItemsPhotoTextBlock > .forminputblock{
    width: 100%;
    margin: 0;
}
.AddWorksItemsPhotoTextBlock > .forminputblock:last-child{
    margin-top: 13px;
}
.AddWorksItemsPhotoInputBlock > label{
    cursor: pointer;
}
.AddWorksItemsPhotoDeleteIcon{
    color: #c4c4c4;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 5px;
}
.AddWorksItemsPhotoDeleteIcon:hover{
    color: black;
}
.AddWorksItemsPhotoImage{
    width: 150px;
    height: 150px;
    object-fit: cover;
}
.AddWorksItemsPhotoImageNull{
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.forminputblock{
    margin: 13px 0;
}
.AddWorksItemsTextArea{
    height: 18px;
    min-height: 18px;
    resize: vertical;
}
#AddWorksItemsPhotoButtonsBlock{
    background: #EBF1F8;
    padding: 13px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
#AddWorksItemsProfessionsAddBlock{
    display: flex;
    align-items: center;
    padding-top: 13px;
}
.AddWorksItemsProfessionsPartBlock{
    border-radius: 7px;
    border:1px solid #326FB5;
    display: flex;
    padding: 3px 13px;
    font-size: 14px;
    color: #326FB5;
    align-items: center;
    margin-right: 13px;
}
.AddWorksItemsProfessionsPartTitle{
    font-family: "OpenSans-Bold";
    margin-right: 13px;
}
.AddWorksItemsProfessionsPartCount{
    font-family: "OpenSans-Regular";
    margin-right: 13px;
}
.AddWorksItemsProfessionsPartDeleteIcon{
    color: #F24040;
    transition: 0.3s;
    opacity: 0.7;
    cursor: pointer;
    font-size: 18px;
}
.AddWorksItemsProfessionsPartDeleteIcon:hover{
    opacity: 1;
}
#topRightAngleSettings{
    padding: 13px;
    overflow: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
#TopRightAngleSettingsTitle{
    font-family: "Roboto-Medium";
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}
.angletoprightrequisitescontractorsitem{
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    color: #000000;
    font-family: "Roboto-Regular";
    font-size: 16px;
    white-space: normal;
    padding: 5px;
    width: max-content;
}
.angletoprightrequisitescontractorsitem.link{
    padding: 8px;
    border-radius: 7px;
    border:1px solid #e4e4e4;
}
.angletoprightrequisitescontractorsitem.bold{
    font-family: "Roboto-Medium";
}
.angletoprightrequisitescontractorsiteminputbold{
    font-family: "Roboto-Medium";
    margin-right: 20px;
}
.angletoprightrequisitescontractorsiteminputbold, .angletoprightrequisitescontractorsiteminputbold>input, .angletoprightrequisitescontractorsiteminputbold>label{
    cursor: pointer;
}
.angletoprightrequisitescontractorsiteminputitalic{
    font-family: "Roboto-Medium";
    margin-right: 20px;
    font-style: italic;
}
.angletoprightrequisitescontractorsiteminputitalic, .angletoprightrequisitescontractorsiteminputitalic>input, .angletoprightrequisitescontractorsiteminputitalic>label{
    cursor: pointer;
}
.angletoprightrequisitescontractorsitemtext{
    width: 180px;
    cursor: pointer;
}
.angletoprightrequisitescontractorsiteminput{
    cursor: pointer;
    margin-right: 13px;
}
.angletoprightrequisitescontractorsitemmarginrowsblock{
    display: flex;
    margin-left: 13px;
}
.angletoprightrequisitescontractorsitemmarginrowsminus{
    margin-right: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.angletoprightrequisitescontractorsitemmarginrowsminus.disabled{
    opacity: 0.5;
    cursor: default;
}
.angletoprightrequisitescontractorsitemmarginrowsplus{
    margin-left: 5px;
    cursor: pointer;
}
.angletoprightrequisitescontractorsitemmoveicon{
    margin-right: 13px;
    cursor: grab;
    font-size: 20px;
}
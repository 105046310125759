#works{
    display: flex;
    width: 100%;
    flex-grow: 1;
}
#WorksTree{
    width: 390px;
    border-right: 1px solid #e0e0e0;
    padding: 13px;
    padding-right: 0;
    position: relative;
}
#WorksTreeContent{
    position: absolute;
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    padding-right: 8px;
    overflow: auto;
}
#WorksMenuBlock{
    font-family: "Roboto-Regular";
    font-size: 14px;
    position: absolute;
    display: none;
    left: 360px;
    background: white;
    border-radius: 7px;
    padding: 10px;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 10%);
    z-index: 1;
}
#WorksMenuBlock.Open{
    display: block;
}
.WorksMenuItemBlock{
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    padding: 5px;
    border-radius: 3px;
}
.WorksMenuItemBlock:hover{
    background: rgba(0, 0, 0, 0.103);
}
.WorksMenuItemIcon{
    margin-right: 5px;
}
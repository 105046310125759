#workplace{
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
}
#WorkplaceArea{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}
#WorkplaceAreaBlock{
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}
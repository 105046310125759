#clients{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
#ClientsAddButtonBlock{
    padding: 20px;
}
#ClientsBackBlock{
    background: #F6F6F6;
    padding: 20px;
    flex-grow: 1;
    overflow: auto;
    position: relative;
}
#ClientsTable{
    border-radius: 5px;
    background: white;
    padding: 13px;
    padding-bottom: 0px;
    width: calc(100% - 26px);
    position: absolute;
}
#ClientsAddButton{
    margin-bottom: 0;
}
.tablebody{
    transition: 0.3s;
    cursor: pointer;
}
.tablebody:hover{
    background: #e4e4e4;
}
.tablebody:last-child > td{
    border-bottom: none;
  }
  .ClientsTablePhotoNameBlock{
      display: flex;
      align-items: center;
  }
  .ClientsTablePhoto{
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
}
#ClientsSearchBlock{
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    background: white;
    padding: 20px;
}
#ClientsSearchInput{
    border: 1px solid #e6e6e6;
    width: 20%;
    padding: 13px;
    outline: none;
    border-radius: 7px;
    font-family: "Roboto-Regular";
}
#ClientsSearchInput::placeholder{
    font-family: "Roboto-Italic";
}
.clientsTableNumberBlock{
    padding-left: 5px!important;
}
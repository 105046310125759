#worksitem{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    background: #F6F6F6;
    padding: 13px;
}
#WorksitemContent{
    background: white;
    box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}
.WorksItemAddButton{
    font-family: "OpenSans-Regular";
    padding: 4px 15px;
    border-radius: 5px;
    transition: 0.3s;
    color: #326FB5;
    border: 1px solid #326FB5;
    cursor: pointer;
    width: max-content;
    font-size: 14px;
}
.WorksItemAddButton:hover{
    color: white;
    background:  #326FB5;
}
#WorksitemHeader{
    padding: 13px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background: #326FB5;
}
#WorksitemHeaderTitle{
    font-family: "OpenSans-SemiBold";
    font-size: 14px;
    color: white;
    cursor: default;
}
#WorksitemHeaderLanguageBlock{
    margin-top: 13px;
    display: flex;
    align-items: center;
    font-family: "OpenSans-SemiBold";
    font-size: 14px;
    color: white;
    cursor: default;
}
.WorksitemHeaderLanguage{
    margin-left: 13px;
    padding: 6px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-family: "OpenSans-Bold";
}
.WorksitemHeaderLanguage:hover{
    background: #ffffff2a;
}
.WorksitemHeaderLanguage.Active{
    color: black;
    background: white;
    font-family: "OpenSans-Bold";
    cursor: default;
}
#WorksItemBody{
    position: relative;
    flex-grow: 1;
}
#WorksItemBodyContent{
    position: absolute;
    overflow: auto;
    width: 100%;
    height: 100%;
}
.WorksItemContentBlock{
    border-bottom:1px solid #326FB5;
    padding: 13px;
    position: relative;
}
.WorksItemContentBlock:last-child{
    border:none;
}
.WorksItemContentTitle{
    color: #326FB5;
    font-size: 14px;
    font-family: "OpenSans-SemiBold";
    margin-bottom: 5px;
    cursor: default;
}
.WorksItemContentText{
    padding: 0;
    color: black;
    font-size: 14px;
    font-family: "OpenSans-Regular";
    outline: none;
    border:none;
    width: 100%;
    resize: vertical;
    height: 22px;
}
.WorksItemContentText:disabled{
    background: white;
}
.WorksItemContentText::placeholder{
    color: #A7A7A7;
    font-size: 14px;
    font-family: "Roboto-Italic";
}
#WorksItemProfessionsTableHeaderBlock{
    display: flex;
    align-items: center;
    border-top:1px solid #C4C4C4;
    border-bottom:1px solid #C4C4C4;
    padding: 13px 0;
    margin-bottom: 13px;
    font-size: 14px;
    color: #A7A7A7;
}
.WorksItemProfessionsTableItemBlock{
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    font-size: 14px;
}
.WorksItemProfessionsTableNumber{
    font-family: "OpenSans-Regular";
    padding-left:13px;
    width: 50px;
    color: #A7A7A7;
}
.WorksItemProfessionsTableNumber{
    font-family: "OpenSans-Regular";
    cursor: default;
}
.WorksItemProfessionsTableProfession{
    font-family: "OpenSans-Regular";
    flex-grow: 1;
    cursor: default;
}
.WorksItemProfessionsTableCount{
    width: 200px;
    font-family: "OpenSans-Regular";
    text-align: center;
    cursor: default;
}
#WorksItemProfessionsNull{
    font-family: "OpenSans-Regular";
    color: #326FB5;
    font-size: 14px;
    margin-bottom: 13px;
    cursor: default;
}
#WorksItemProfessionsTableAddBlock{
    display: flex;
    align-items: center;
}
#WorksItemProfessionsTableAddSelect{
    cursor: pointer;
    height: 26px;
    width: 350px;
    margin-right: 13px;
    padding: 1px 10px;
    border:1px solid #C4C4C4;
    border-radius: 3px;
    outline: none;
    font-family: "OpenSans-Regular";
}
#WorksItemProfessionsTableAddCount{
    height: 22px;
    width: 40px;
    margin-right: 13px;
    padding: 1px 10px;
    border:1px solid #C4C4C4;
    border-radius: 3px;
    outline: none;
    font-family: "OpenSans-Regular";
}
.WorksItemProfessionsTableAddIcon{
    cursor: pointer;
    margin-right: 13px;
    color: #a7a7a7;
    transition: 0.3s;
}
.WorksItemProfessionsTableAddIcon:hover{
    color: black;
}
.StageBlock{
    padding: 13px;
    border: 1px solid #C4C4C4;
    margin-bottom: 13px;
    border-radius: 5px;
}
.StageHeader{
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
}
.StageHeaderMenuButtonBlock{
    display: flex;
}
.StageHeaderMenuButton{
    color:#A7A7A7;
    transition: 0.3s;
    margin-left: 13px;
    cursor: pointer;
}
.StageHeaderMenuButton:hover{
    color:black;
}
.StageTitle{
    font-family: "OpenSans-Bold";
    font-size: 14px;
}
.StageFotofixierung{
    width: 25px;
    height: 25px;
}
.StageFotofixierung.No{
    color: #C4C4C4;
}
.StagePhotoBlock{
    display: flex;
    margin-bottom: 13px;
}
.StagePhotoBlock:last-child{
    margin-bottom: 0px;
}
.StagePhoto{
    width: 100px;
    height: 100px;
    margin-right: 20px;
}
.StageTextBlock{
    flex-grow: 1;
}
.StageText{
    font-family: "OpenSans-Regular";
    font-size: 14px;
}
.StageProfessionsBlock{
    display: flex;
    flex-wrap: wrap;
    margin-top: 13px;
}
.StageProfessionsItemBlock{
    border-radius: 7px;
    border:1px solid #326FB5;
    display: flex;
    padding: 3px 13px;
    font-size: 14px;
    color: #326FB5;
    align-items: center;
    margin-right: 13px;
    width: max-content;
    font-family: "OpenSans-Regular";
}
.StageProfessionsItemTitle{
    font-family: "OpenSans-Bold";
    margin-right: 5px;
}
.StageNote{
    border-radius: 7px;
    border:1px solid #DF6D6D;
    padding: 5px 13px;
    color: #DF6D6D;
    margin-top: 5px;
}

#worksitemnull{
    position: absolute;
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color:#4B4B4B;
    font-family: "Roboto-Regular";
    font-size: 16px;
}
#worksitemnullicon{
    color: #A7A7A7;
    margin-bottom: 5px;
    font-size: 26px;
}
.NodeMainParent{
    border: 1px solid #326FB5;
    border-radius: 5px;
    margin-bottom: 13px;
    transition: 0.3s;
}
.NodeMainParent.Open{
    padding-bottom: 5px;
}

.NodeMainParentHeader{
    font-family: "OpenSans-SemiBold";
    padding: 18px 13px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.NodeMainParentHeader.Open{
    background: #326FB5;
    color: white;
}

.NodeHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px 6px 5px;
    border-radius: 5px;
    transition: 0.3s;
}
.NodeHeader:hover{
    background: #d6d6d6;
}
.NodeHeader.Open{
    background: #316eb41c;
}
.NodeHeaderTitleBlock{
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-grow: 1;
    font-size: 16px;
}
.NodeHeaderTitleInput{
    border:none;
    outline: none;
    padding: 0;
    height: 21px;
    font-size: 16px;
    flex-grow: 1;
}


.NodeListBlock{
    display: none;
    padding: 5px;
    padding-left: 35px;
    padding-right: 0px;
}
.NodeListBlock.Open{
    display: block;
}
.NodeMainParent > .NodeListBlock{
    padding-left: 5px;
    padding-right: 5px;
}
.NodeIcon{
    width: 16px;
    height: 16px;
    margin-right: 5px;
    font-size: 16px;
}

.ArrowIcon{
    cursor: pointer;
    transition: 0.3s;
}
.ArrowIcon.Open{
    transform: rotateZ(180deg);
}


.DotsIcon{
    cursor: pointer;
    opacity: 0;
    transition: 0.3s;
}
.NodeMainParentHeader:hover > .DotsIcon{
    opacity: 1;
}
.NodeHeader:hover > .DotsIcon{
    opacity: 1;
}

/* Блок добавления */
#WorksTreeAddParentBlock{
    padding: 13px;
    border: 1px solid #326FB5;
    border-radius: 5px;
}
#WorksTreeAddInputBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#WorksTreeAddInput{
    border:none;
    outline: none;
    font-family: "Roboto-Regular";
    font-size: 14px;
}
#WorksTreeAddInput::placeholder{
    font-family: "Roboto-Italic";
}
#WorksTreeAddClear{
    transition: 0.3s;
    color:#7A7F83;
    cursor: pointer;
}
#WorksTreeAddClear:hover{
    color: #326FB5;
}
#WorksTreeAddButtonsBlock{
    display: flex;
    align-items: center;
    margin-top: 13px;
}
#WorksTreeAddBlockParentButton{
    cursor: pointer;
    background: white;
    border:1px solid #326FB5;
    color: #326FB5;
    font-size: 14px;
    font-family: "Roboto-Regular";
    transition: 0.3s;
    width: max-content;
    padding: 4px 15px;
    border-radius: 5px;
}
#WorksTreeAddBlockParentButton:hover{
    background:#326FB5;
    color: white;
}
#WorksTreeCancelBlockParentButton{
    cursor: pointer;
    background: white;
    color: #7A7F83;
    font-size: 14px;
    font-family: "Roboto-Regular";
    transition: 0.3s;
    width: max-content;
    padding: 4px 0px;
    margin-left: 15px;
    border-radius: 5px;
}
#WorksTreeCancelBlockParentButton:hover{
    color: #326FB5;
}
#WorksTreeAddParentButton{
    cursor: pointer;
    background: white;
    border:1px solid #32B596;
    color: #32B596;
    font-size: 14px;
    font-family: "Roboto-Regular";
    transition: 0.3s;
    width: max-content;
    padding: 4px 15px;
    border-radius: 5px;
}
#WorksTreeAddParentButton:hover{
    background:#32B596;
    color: white;
}
#footerCPSettings {
    flex-grow: 1;
    display: flex;
}

#FooterCPSettingsItemsBlock {
    border-right: 1px solid #e9e9e9;
    position: relative;
    overflow: auto;
    width: 200px;
}

#FooterCPSettingsItemsContentBlock {
    position: absolute;
    width: 100%;
}

.footerCPSettingsItem {
    cursor: pointer;
    padding: 13px;
    border-bottom: 1px solid #e9e9e9;
    transition: 0.3s;
}

.footerCPSettingsItem:hover {
    background: #e9e9e9;
}

#FooterCPSettingsColumnsBlock {
    flex-grow: 1;
    position: relative;
    overflow: auto;
    display: flex;
}

#FooterCPSettingsColumnsContentBlock {
    position: absolute;
    display: flex;
    height: 100%;
    padding: 13px;
    box-sizing: border-box;
}

#FooterCPSettingsAddColumnsButton{
    cursor: pointer;
    white-space: nowrap;
    padding: 5px 13px;
    background: #e9e9e9;
    border-radius: 7px;
    transition: 0.3s;
}
#FooterCPSettingsAddColumnsButton:hover{
    background: #a7a7a7;
}

.footerCPSettingsColumnBlock {
    min-width: 200px;
    background: #f1f1f1;
    border-radius: 7px;
    margin-right: 13px;
    overflow: auto;
}

.footerCPSettingsColumnFooterBlock {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    padding-bottom: 5px;
    border-bottom: 1px dashed #a7a7a7;
    align-items: center;
}

.footerCPSettingsColumnFooterCrossIcon {
    color: #a7a7a7;
    font-size: 10px;
    transition: 0.3s;
    cursor: pointer;
    margin-left: 13px;
}

.footerCPSettingsColumnFooterCrossIcon:hover {
    color: black;
}

.footerCPSettingsColumnItemBlock{
    padding: 13px;
    border: 1px dashed #a7a7a7;
    border-radius: 7px;
    margin: 13px;
    display: flex;
}

.footerCPSettingsColumnItemHeaderBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#FooterCPSettingsColumnItemOrderBlock{
    padding-right: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.footerCPSettingsColumnItemOrderItem{
    color: #d0d0d0;
    transition: 0.3s;
}
.footerCPSettingsColumnItemOrderItem.Active{
    color: #a7a7a7;
    cursor: pointer;
}
.footerCPSettingsColumnItemOrderItem.Active:hover{
    color: black;
}
#MSfooter{
    margin-top: 35px;
    display: block;
    margin-bottom: 75px;
}
.MSfooteritem{
    display: flex;
    width: 100%;
}
.MSfooterblockitem{
    display: flex;
    margin: 10px 40px;
}
.MSfootericon{
    margin: auto;
    margin-right: 15px;
}
.MSfootertext{
    font-family: "NunitoSans-Regular";
    color: #3C3C3C;
    font-size: 22px;
}
#MSfootertitle{
    font-family: "NunitoSans-SemiBold";
    font-size: 18px;
    color: #7A7F83;
    text-align: center;
    width: 100%;
    padding-bottom: 35px;
}

@media (min-width: 1024px) {
    .MSfooteritem{
        display: flex;
        width: 33.3%;
    }
    #MSfooter{
        display: flex;
    }
    .MSfooterblockitem{        
        margin: auto;
    }
  }
  
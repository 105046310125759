#header{
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    position: relative;
}
#HeaderLogOut{
    cursor: pointer;
    font-family: "Roboto-Regular";
    color: #343434;
    font-size: 20px;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
}
#HeaderLogOutIcon{
    padding-right: 5px;
    width: 18px;
}
#HeaderPeopleIcon{
    padding-right: 5px;
    width: 18px;
}
#HeaderName{
    font-family: "Roboto-Regular";
    font-size: 20px;
    margin-right: 15px;
    color: #3C3C3C;
    display: flex;
    align-items: center;
}
#HeaderLanguageBlock{
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    font-size: 20px;
    color: #3C3C3C;
}
#HeaderLanguageSelectorBlock{
    cursor: default;
    display: none;
    position: absolute;
    margin-top: 7px;
    background: white;
    text-align: center;
    padding: 30px;
    font-size: 16px;
    color: #7A7F83;    
    font-family: 'NunitoSans-Regular';
    z-index: 1002;
    box-shadow: 0px 0px 10px 8px rgba(214, 214, 214, 0.2);
    left: calc(-120% - 25px);
}
#HeaderLanguageSelectorBlock:before {
    border-bottom: 5px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: "";
    height: 0;
    left: 40%;
    position: absolute;
    top: -5px;
    width: 0;
  }

#HeaderLanguageSelectorBlock.Open {
    display: block;
}
.HeaderLanguageBlockItem{
    font-size: 16px;
    color: #7A7F83;    
    font-family: 'NunitoSans-Regular';
    margin-bottom: 30px;
    cursor: pointer;
}
.HeaderLanguageBlockItem:hover{
    color: #326FB5;
}
.HeaderLanguageBlockItem:last-child{
    margin-bottom: 0px;
}
#HeaderPersData{
    display: flex;
}
#HeaderTitle{
    margin-left: 20px;
    font-family: "OpenSans-Bold";
    font-size: 21px;
    display: flex;
    align-items: center;
}

.backArrow{
    color: #A7A7A7;
    cursor: pointer;
    position: absolute;
    left: 0;
    transition: 0.3s;
    top: 11px;
}
.backArrow:hover{
    color: #326fb5;
}
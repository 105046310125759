#localization{
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;
}
#LanguageHeader{
    color: #AFB8BF;
    border: none;
}
#LanguageHeader:hover{
    background: white;
}
.LanguageRow{
    display: flex;
    margin-top: 10px;
    border: 1px solid #E9E9E9;
    padding: 10px;
    border-radius: 7px;
    font-family: "NunitoSans-Regular";
    color: #444444;
    transition: all 0.3s linear;
}
.LanguageRow:hover{
    background: #F6F6F6;
}
.LanguageRow > div
{
    width: 20%;
}
#LocalizationAdd{
    cursor: pointer;
    transition: all 0.3s linear;
}
#LocalizationAdd:hover{
    color: #326FB5;
}
.LocalizationChildBlock{
    margin-left: 20px;
    border-left: 3px solid #326fb51f;
    padding-left: 5px;
    display: none;
}
.TextPhrase.Open ~ .LocalizationChildBlock{
    display: block;
}
.TextPhrase{
    font-family: "OpenSans-Regular";
    display: flex;
    align-items: center;
    font-size: 18px;
    background: transparent;
    width: max-content;
}
.TextPhrase.Parent{
    cursor: pointer;
}
.LocalizationChildBlockTranslate{
    border: 1px solid #e9e9e9;
    width: max-content;
    padding: 10px 20px;
    padding-bottom: 0;
    background: #326fb51f;
    margin-left: 10px;
}
.LocalizationChildBlockTranslateItem{
    margin-bottom: 10px;
}
.LocalizationChildBlockTranslateItemLanguage{
    color:#AFB8BF;
}
.LocalizationItem{
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
}
.LocalizationItemChild{
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
}
.LocalizationItemArrow{
    font-size: 16px;
    margin-left: 3px;
    transition: 0.3s;
    z-index: -1;
}
.TextPhrase.Open > .LocalizationItemArrow{
    transform: rotateZ(180deg);
}
#Contractor{
    display: flex;
    position: relative;
    height: 100%;
}
#ContractorDataBlock{
    width: 300px;
    box-shadow: 5px 0px 15px rgb(0 0 0 / 10%);
}
#ContractorDataContentBlock{
    padding: 13px;
    padding-bottom: 0;
}

.contractorblock{
    margin-bottom: 13px;
}
.contractortitle{
    color: #A7A7A7;
    font-family: "Roboto-Regular";
    font-size: 14px;
}
.contractorinput{
    outline:none;
    border: none;
    padding: 0;
    font-family: "Roboto-Medium";
    font-size: 14px;
    width: 100%;
    padding-bottom: 2px;
}
.contractorinput::placeholder{
    color: #D0D0D0;
    font-family: "Roboto-Italic";
    font-size: 14px;
}

/* История изменений */
#ContractorHistoryBlock{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
#ContractorHistory{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 13px;
    position: relative;
}
#ContractorHistoryContent{
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    position: absolute;
    overflow: auto;
    padding-right: 13px;
}
#ContractorHistoryContent:has{
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    position: absolute;
    overflow: auto;
    padding-right: 13px;
}
#ContractorHistoryInputBlock{
    height: 105px;
    padding: 13px;
    display: flex;
    flex-direction: column;
}
#ContractorHistoryInputArea{
    padding: 10px;
    border: 1px solid #00000020;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    flex-grow: 1;
    border-top: 1px solid #326FB5;
}
.contractorHistoryInputTitleBlock{
    display: flex;
}
.contractorHistoryInputTitle{
    color: #326FB5;
    padding: 5px 10px;
    display:flex;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: max-content;
    font-family: "Roboto-Regular";
    font-size: 14px;
    transition: 0.3s;
}
.contractorHistoryInput{
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-family: "Roboto-Regular";
}
.contractorHistoryInput::placeholder{
    font-family: "Roboto-Italic";
    font-size: 14px;
}
.contractorHistoryInputTitle.Active{
    color: white;
    background: #326FB5;
}
.contractorHistoryInputAddButton{
    outline: none;
    background: white;
    color: #326FB5;
    border:1px solid #326FB5;
    border-radius: 5px;
    padding: 5px 10px;
    font-family: "Roboto-Regular";
    margin-top: 5px;
    cursor: pointer;
    transition: 0.3s;
    width: max-content;
    font-size: 12px;
}
.contractorHistoryInputAddButton:hover{
    color : white;
    background: #326FB5;
}
.contractorHistoryClassNote{
    display: flex;
    background: #ffc55959;
    border-radius: 10px;
    padding: 13px;
    margin-bottom: 13px;
    color: #F2B43F;
}
.contractorHistoryClassEdit{
    display: flex;
    background: white;
    border-radius: 10px;
    padding: 0 13px;
    color: #326FB5;
    align-items: center;
}
.contractorHistoryIcon{
    margin-right: 13px;
    width: 24px;
    height: 24px;
}
.contractorHistoryBorder{
    border-right: 2px solid #326FB5;
    width: 2px;
    height: 50px;
    margin-right: 13px;
    margin-left: 20px;
    position: relative;
}
.contractorHistoryBorderPoint{
    position: absolute;
    width: 10px;
    height: 10px;
    background: #326FB5;
    top: calc(50% - 5px);
    right: -6px;
    border-radius: 50%;
}
.contractorHistoryDate{
    margin-right: 13px;
    color: inherit;
    font-family: "Roboto-Regular";
    font-size: 14px;
    line-height: 24px;
}
.contractorHistoryPeople{
    color: black;
    font-family: "Roboto-Bold";
    font-size: 14px;  
    line-height: 24px;
}
.contractorHistoryBlock{
    display: flex;
    align-items: center;
}
.contractorHistoryText{
    margin-left: 13px;
    font-family: "Roboto-Regular";
    color:black;
    font-size: 14px;  
    line-height: 24px;
}
.contractorHistoryNote{
    font-family: "Roboto-Italic";
    font-size: 14px;  
    color: #00000067;
}

.contractorHistoryContentItem{
    position: relative;
}
.contractorHistoryDateBlock{
    border-radius: 20px;
    background: #eaf0f7;
    padding: 5px 10px;
    margin: 0 auto;
    width: max-content;
    color: #326FB5;
    position: sticky;
    top: 0px;
    margin-bottom: 13px;
}
.contractorHistoryTextChangValueBlock{
    display: flex;
    align-items: center;
}
.contractorHistoryTextChangValueArrow{
    color: #A7A7A7;
}
/* ----- */

#LineInfo{
    width: 100%;
    height: 1px;
    background: #00000024;
    margin-bottom: 13px;
}

#RequisitesModalBlock{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;
    align-items: center;
    justify-content: center;
}
#RequisitesModalBlock.Open{
    display: flex;
}

#RequisitesModal{
    width: 500px;
    height: max-content;
    background: white;
    border-radius: 5px;
    padding: 20px;
}

#RequisitesModalHeader{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

#RequisitesModalCancel{
    cursor: pointer;
    background: #DDEFFD;
    transition: 0.3s;
    font-size: 14px;
    padding: 10px 20px;
    color: #326FB5;
    margin-right: 13px;
    border-radius: 5px;
    font-family: "OpenSans-Regular";
}
#RequisitesModalCancel:hover{
    background: #cce5fa;
}

#RequisitesModalHeaderTitle{
    font-family: "OpenSans-Bold";
    color: black;
}

#RequisitesAddButtonBlock{
    display: flex;
    justify-content: flex-end;
}

#RequisitesAddButton{
    margin-bottom: 0;
    background: #326FB5;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}
#RequisitesAddButton:hover{
    background: #275c99;
}

#RequisitesContractorButton{
    font-family: "Roboto-Medium";
    cursor: pointer;
    padding: 15px 13px;
    background: #DDEFFD;
    color:#326FB5;
    transition: 0.3s;
    margin-right: 30px;
    font-size: 16px;
    width: max-content;
    width: calc(100% - 26px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#RequisitesContractorButton:hover{
    color:white;
    background:#326FB5;
}

#RequisitesContractorAddButton{
    color:#326FB5;
    font-family: "OpenSans-SemiBold";
    margin-left: 13px;
    cursor: pointer;
}
#RequisitesContractorAddButton:hover{
    color:#275c99;
}
#RequisitesLogoBlock{
    display: flex;
}
#RequisitesLogo{
    display: none;
}
#RequisitesLogoLabelBlock{
    width: 200px;
    padding: 13px 0;
    border: 2px dashed #A7A7A7;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}
#RequisitesLogoLabelIcon{
    font-size: 30px;
    color:#A7A7A7;
}
#RequisitesLogoLabelText{
    font-size: 10px;
    color:#A7A7A7;
    font-family: "Roboto-Regular";
}
#RequisitesLogoLabelImage{
    height: 100px;
    object-fit: cover;
}
#RequisitesLogoLabel{
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#goodsservices {
    display: flex;
    flex-direction: column;
    padding: 13px;
    flex-grow: 1;
    overflow: auto;
}

#GoodsServicesCategoriesBlock {
    display: flex;
    flex-wrap: wrap;
}

#GoodsServicesPlusBlock {
    text-align: center;
    font-family: "OpenSans-Regular";
    font-weight: 600;
    font-size: 14px;
    color: #1B1B1B;
    cursor: pointer;
}

#GoodsServicesPlusIcon {
    margin-bottom: 10px;
    width: 32px;
    height: 32px;
    padding: 14px;
    background: #F1FFF5;
    border-radius: 8px;
    transition: 0.3s;
}

#GoodsServicesPlusBlock:hover>div>#GoodsServicesPlusIcon {
    /* background: #e2fbe9; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.goodsServicesCategoryBlock {
    text-align: center;
    font-family: "OpenSans-Regular";
    font-weight: 600;
    font-size: 14px;
    color: #1B1B1B;
    margin-right: 80px;
    margin-bottom: 13px;
}

.goodsServicesCategoryItem {
    cursor: pointer;
    margin-bottom: 10px;
    width: 32px;
    height: 32px;
    padding: 14px;
    background: #EDF5FF;
    border-radius: 8px;
    transition: 0.3s;
}

.goodsServicesCategoryItem:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.goodsServicesCategoryItemTitle {
    width: 80px;
    cursor: text;
    max-width: 80px;
    word-break: break-word;
    white-space: pre-wrap;
}

#GoodsServicesAddButtonFilterButtonsBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#GoodsServicesAddButton {
    font-family: "OpenSans-Regular";
    font-weight: 600;
    font-size: 14px;
    background: #326FB5;
    color: white;
    border-radius: 6px;
    padding: 10px 16px;
    display: flex;
    width: max-content;
    margin-top: 13px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
}

#GoodsServicesAddButton:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#GoodsServicesAddButtonPlusIcon {
    margin-left: 5px;
}

#GoodsServicesFilterButtonsBlock {
    display: flex;
    align-items: center;
}

.goodsServicesFilterButton {
    font-family: "OpenSans-Regular";
    font-weight: 600;
    font-size: 14px;
    background: white;
    color: #326FB5;
    border: 1px solid #326FB5;
    border-radius: 6px;
    padding: 10px 16px;
    display: flex;
    width: max-content;
    margin-right: 13px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
}

.goodsServicesFilterButton.Active {
    background: #326FB5;
    color: white;
}

.goodsServicesFilterButton:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#GoodsServicesPaginationBlock {
    border-top: 1px solid #E3E3E3;
    display: flex;
    position: relative;
    justify-content: center;
    padding-top: 15px;
}

#GoodsServicesPaginationCount {
    position: absolute;
    top: 15px;
    left: 0;
    color: #A8A8A8;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: "Roboto-Regular";
}

#addgoodsservices {
    padding: 13px;
}
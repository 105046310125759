#settingsContractors {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 13px;
}

#settingsMenuBlock {
    display: flex;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 13px;
    padding-bottom: 5px;
}

.settingsMenuItem {
    transition: .3s;
    padding: 13px;
    cursor: pointer;
    font-family: "OpenSans-SemiBold";
    display: flex;
    border-radius: 7px;
    font-size: 14px;
    margin-right: 5px;
}

.settingsMenuItem:hover {
    background: #afb8bf33;
}

.settingsMenuItem.Active {
    background: #D9F0FF;
    color: #326fb5;
    cursor: default;
}

#settingsContentBlock {
    display: flex;
    flex-grow: 1;
    position: relative;
}
@keyframes openlogin {
    from {
        width: 100%;
    }
  
    to {
        width: 100%;
        opacity: 1;
    }
  }
  @keyframes closelogin {
    0%{
        width: 100%;
        opacity: 1;
    }

    99%{
        width: 100%;
        opacity: 0;
    }
    100%{
        width: 0%;
    }
  }
#LoginBlockFade{
    position: fixed;
    height: 100%;
    z-index: -1;
    top: 107px;
    background: transparent;
    width: 0%;
    opacity:0;
    animation: closelogin 0.3s linear forwards;  
}
#LoginBlockFade.Open{
    z-index: 1001; 
    animation: openlogin 0.3s linear forwards;  
}
#LoginBlock{
    top: 0;
    background: white;
    width: 0%;
    height: calc(100% - 107px);
    position: absolute;
    right: -100%;
    transition: all 0.3s linear;
    display: flex;
}
#LoginBlock.Open{
    right: 0;
    width: 100%;
} 
#LoginBlock > #LoginBlockFormBlock{
    display: none;
} 
#LoginBlock.Open > #LoginBlockFormBlock{
    display: block;
} 
#LoginBlockBackBlock{
    position: absolute;
    height: 107px;
    display: none;
}
#LoginBlockBackButton{
    color: #7A7F83;
    font-family: "NunitoSans-SemiBold";
    font-size: 16px;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    display: flex;
    transition: all 0.3s linear;
}
#LoginBlockBackButton:hover{
    text-shadow: #ccc 1px 0 10px;
}
#LoginBlockBackButtonIcon{
    transform: rotateY(180deg);
    padding-left: 5px;
    font-size: 20px;
}
#LoginBlockFormBlock{
    margin: auto;
}
.LoginBlockFormTitle{
    font-size: 25px;
    font-family: "NunitoSans-Bold";
    color: #3C3C3C;
    max-width: 375px;
    text-align: center;
}
.LoginBlockFormInputBlock{
    position: relative;
    margin-top: 50px;
    max-width: 345px;
}
.LoginBlockFormInput{
    border: 2px solid #C4C4C4;
    border-radius: 10px;
    padding: 15px;
    outline: none;
    font-size: 30px;
    color: #3C3C3C;
    width: -webkit-fill-available;
}
.LoginBlockFormInput:focus{
    border: 2px solid #326FB5;
}
#LoginBlockFormForgotYourPassword{
    color: #326FB5;
    font-size: 15px;
    font-family: "NunitoSans-Regular";
    cursor: pointer;
    width: max-content;
    margin-left: auto;
    margin-top: 25px;
}
#LoginBlockFormForgotYourPassword:hover{
    text-decoration: underline;
}
#LoginBlockFormRememberMe{
    position: absolute;
    z-index: -1;
    opacity: 0; 
    cursor: pointer;
}
#LoginBlockFormRememberMe+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    margin-top: 25px;
  }
  #LoginBlockFormRememberMe+label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  #LoginBlockFormRememberMe:checked+label::before {
    border-color: #326FB5;
    background-color: #326FB5;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
  #LoginBlockFormRememberMeTitle{
      font-size: 16px;
      color: #727272;
      font-family: "NunitoSans-SemiBold";
      cursor: pointer;
  }
#LoginBlockFormButtonLogin{
    width: 169px;
    height: 49px;
    background: #A8D1ED;
    box-shadow: 0px 4px 20px 10px rgba(167, 167, 167, 0.1);
    border-radius: 15px;
    outline: none;
    border: none;
    color: #326FB5;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;
    margin: auto;
    margin-top: 25px;
    cursor: pointer;
    font-size: 22px;
    font-family: "NunitoSans-SemiBold";
}
#LoginBlockFormButtonLogin:hover{
    color: #FFFFFF;
    background: #336FB5;
}
@media (min-width: 1024px) {
    #LoginBlockFade{
        top: 0;
        background: rgba(0, 0, 0, 0.219);
    }
    #LoginBlock.Open{
        width: 560px;
        height: 100%;
        position: absolute;
        top: 0;
    } 
    #LoginBlockFormButtonLogin{
        width: max-content;
        height: 67px;
        padding: 25px 90px;
    }
    #LoginBlockFormBlock{
        margin: auto;
    }
    #LoginBlockBackBlock{
        display: flex;
    }
    .LoginBlockFormTitle{
    font-size: 36px;
    max-width: 454px;
    }
    #LoginBlockFormForgotYourPassword{
        font-size: 20px;
    }
    #LoginBlockFormRememberMeTitle{
        font-size: 22px;
    }
    .LoginBlockFormInputBlock{
        max-width: 428px;
    }
}

#LoginBlockFormNoAuthBlock{
    position: relative;
    margin-top: 25px;
    display: flex;
    justify-content: center;
}
#LoginBlockFormNoAuth{
    position: absolute;
    color: #FC4141;
    font-family: "Roboto-Bold";
    transition: 0.3s;
}
#LoginBlockFormNoAuth.hidden{
    opacity: 0;
}